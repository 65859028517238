import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechasfiltro'
})

export class FechasfiltroPipe implements PipeTransform {
  nombremes = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];

  transform(fecha: any, idx:any) {
    let valor ="";
    if (idx == 0){
      valor = this.nombremes[parseInt(fecha.mes)-1]+"'"+fecha.agno.substr(-2);
    }
    else{
      valor = fecha.dia;
    }

    return valor;
    }
}
