import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NavController, ModalController, Platform } from '@ionic/angular';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
import { Title } from '@angular/platform-browser';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-noticias-detalle',
  templateUrl: './noticias-detalle.component.html',
  styleUrls: ['./noticias-detalle.component.scss'],
})
export class NoticiasDetalleComponent  implements OnInit, OnDestroy {

  noticia:any;
  private sub:any;
  ruta:any;
  nid:any;

  constructor(
    public navCtrl: NavController,
    private router:Router, 
    private route: ActivatedRoute,
    public service: ServicioCarnavalService,
    private titleService: Title
    ) { 
    //console.log("en detalle");
    //console.log(this.service.noticia_item);
    this.sub = this.route.params.subscribe(params => {
      this.nid = params['nid']; // (+) converts string 'id' to a number
      this.service.cargarNoticiasCarnaval(this.nid,"all");
      //console.log(this.service.noticia_item);
      });
  }

  ngOnInit() {
    this.service.setearpantallaFB("Noticias-detalle2","Noticias-detalleScreen");
    this.service.comprobarPubli("app-carnaval-actualidad-noticias");
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }
  regresar(){
    this.navCtrl.back();
  }

}
