<ion-app>
  <ion-menu contentId="main-content" type="overlay">
    <ion-content class="menu1"> 
      <ion-list-header>
        <h2 class="text-center" [routerDirection]="'root'" [routerLink]="['/']" >
        <ion-icon aria-hidden="true" slot="start" [ios]="'home-outline'" [md]="'home-sharp'"></ion-icon>
        &nbsp;&nbsp;Carnaval
      </h2>
      </ion-list-header>
      <ion-list id="inbox-list" lines="none">  
          <ion-accordion-group >
            <ion-accordion *ngFor="let p of appPages; let i = index">
              <ion-menu-toggle slot="header" *ngIf="p.url!='#'">
                <ion-item  routerDirection="root" [routerLink]="[p.url]" lines="inset" detail="false" routerLinkActive="selected">
                  <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-item *ngIf="p.url=='#'" slot="header" lines="inset">
                <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
              <ion-menu-toggle slot="content"  *ngFor="let s of p.subPages">
                <ion-item class="dos" [routerDirection]="'root'" [routerLink]="[s.url]">
                  <ion-icon aria-hidden="true" slot="start" [ios]="s.icon + '-outline'" [md]="s.icon + '-sharp'"></ion-icon>
                  <ion-label>{{s.title}}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-accordion>
            <ion-accordion>
              <ion-item (click)="salir()" slot="header" lines="inset">
                <ion-icon aria-hidden="true" slot="start" [ios]="'close-outline'" [md]="'close-sharp'"></ion-icon>
                <ion-label>Salir</ion-label>
              </ion-item>
            </ion-accordion>
          </ion-accordion-group>
      </ion-list>
      <div class="footer">
        <ion-img src='assets/logo_onda_cadiz_120.png'></ion-img>
      </div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>

