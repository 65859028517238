import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrodiass'
})
export class FiltrodiassPipe implements PipeTransform {

  transform(value: Array<any>, args: string =''): Array<any> {
    if (!args || args === 'Todos'){
      /*console.log("no recibo argumento");*/
      return value;
    }
   /* return value.filter(({ dia }) => dia.toLowerCase() === args);*/
   return value.filter(({ node }) => node.dia.toLowerCase() === args);

  }

}
