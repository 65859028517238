<ion-content class="modalPubli">
  <div class="contenido {{publi.node.formato}}">
    <!--div *ngFor="let publi of service.ajustes.publicidades[0];"-->
      <div class="modal-publi ">
        <ion-icon  (click)="cerrarModal(1)" name="close-circle" size="large" float-end></ion-icon>
        <ion-item  (click)="service.iraEnlace(publi.node.url)">
          <img *ngIf="!establet()" src="{{publi.node.imagen_telefono}}" />
          <img *ngIf="establet()" src="{{publi.node.imagen_tableta}}" />
        </ion-item>
      </div>
    <!--/div-->
  </div>
</ion-content>