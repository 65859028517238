import { Component, Input, OnInit, ViewChild,ElementRef} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-mensaje',
  templateUrl: './modal-mensaje.html',
  styleUrls: ['./modal-mensaje.scss'],
})
export class ModalMensajeComponent  implements OnInit {

  @Input() mensaje1:string;
  @Input() mensaje2:string;
constructor(
  private modalCtrl:ModalController
  //private firebaseAnalytics:FirebaseAnalytics
  ){
  this.mensaje1 = "";
  this.mensaje2 = "";
}

ngOnInit(): void {
}

cerrarModal(op:number){
  if (op){
    return this.modalCtrl.dismiss({'dismissed':false });
  }else{
    return this.modalCtrl.dismiss({'dismissed':true });
  }
    
}

}
