<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Ranking</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    
    <ion-item>
            <ion-select slot="end" class="tipoagrupacion" name="tipoagrupacion" interface="popover" value="all" #tipoagrupacion >
              <div slot="label">Modalidad:</div>
              <ion-select-option value="all">Todos</ion-select-option>
              <ion-select-option value="Coros">Coros</ion-select-option>
              <ion-select-option value="Comparsas">Comparsas</ion-select-option>
              <ion-select-option value="Chirigotas">Chirigotas</ion-select-option>
              <ion-select-option value="Cuartetos">Cuartetos</ion-select-option>
            </ion-select>
    </ion-item>

    <ion-segment class="rango" name="rango" interface="popover" (ionChange)="filterData(rango.value)" #rango>
      <ion-segment-button value="all">General</ion-segment-button>
      <ion-segment-button value="day">Hoy</ion-segment-button>
    </ion-segment>
    <ion-grid>

      <ion-list>

        <span *ngIf="tipoagrupacion.value == 'Coros'">
          <ion-row  *ngFor="let ranking of service.rankingcoros; let i = index;">
              <ion-col size-md="2" size-sm="2" size-xs="2">
                <div class="orden">{{i+1}}</div>
              </ion-col>
              <ion-col size-md="8" size-sm="8" size-xs="8">               
                <div color="secundary" text-left>{{ranking.node.grouptype}}</div>
                <div>{{ranking.node.title}}</div>
              </ion-col>
              <ion-col size-md="2" size-sm="2" size-xs="2" class="puntos">
                {{ranking.node.points}}
              </ion-col>
            </ion-row>
        </span>
        <span *ngIf="tipoagrupacion.value == 'Comparsas'">
          <ion-row  *ngFor="let ranking of service.rankingcomparsas; let i = index;">
            <ion-col size-md="2" size-sm="2" size-xs="2">
              <div class="orden">{{i+1}}</div>
            </ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="8">  
              <div color="secundary" text-left>{{ranking.node.grouptype}}</div>
              <div>{{ranking.node.title}}</div>
            </ion-col>
            <ion-col size-md="2" size-sm="2" size-xs="2" class="puntos">
              {{ranking.node.points}}p
            </ion-col>
          </ion-row>
        </span>
      
        <span *ngIf="tipoagrupacion.value == 'Chirigotas'">
          <ion-row  *ngFor="let ranking of service.rankingchirigotas; let i = index;">
            <ion-col size-md="2" size-sm="2" size-xs="2">
              <div class="orden">{{i+1}}</div>
            </ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="8">  
              <div color="secundary" text-left>{{ranking.node.grouptype}}</div>
              <div>{{ranking.node.title}}</div>
            </ion-col>
            <ion-col size-md="2" size-sm="2" size-xs="2" class="puntos">
              {{ranking.node.points}}p
            </ion-col>
          </ion-row>
        </span>
      
        <span *ngIf="tipoagrupacion.value == 'Cuartetos'">
          <ion-row  *ngFor="let ranking of service.rankingcuartetos; let i = index;">
            <ion-col size-md="2" size-sm="2" size-xs="2">
              <div class="orden">{{i+1}}</div>
            </ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="8">  
              <div color="secundary" text-left>{{ranking.node.grouptype}}</div>
              <div>{{ranking.node.title}}</div>
            </ion-col>
            <ion-col size-md="2" size-sm="2" size-xs="2" class="puntos">
              {{ranking.node.points}}p
            </ion-col>
          </ion-row>
        </span>
      
        <span *ngIf="tipoagrupacion.value == 'all'">
          <ion-row  *ngFor="let ranking of service.ranking; let i = index;">
            <ion-col size-md="2" size-sm="2" size-xs="2">
              <div class="orden">{{i+1}}</div>
            </ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="8">  
              <div color="secundary" text-left>{{ranking.node.grouptype}}</div>
              <div>{{ranking.node.title}}</div>
            </ion-col>
            <ion-col size-md="2" size-sm="2" size-xs="2" class="puntos">
              {{ranking.node.points}}
            </ion-col>
          </ion-row>
        </span>
      </ion-list>
      </ion-grid>

  </div>
</ion-content>