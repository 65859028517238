import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeurl'
})
export class SafeurlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/' + this.getVideoId(url) + "?autoplay=1");
  }

  getVideoId(url:string) {
    var video, match;
    if (url === null) {
        return '';
    }
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    match = url.match(regExp);
    video = (match&&match[7].length==11)? match[7] : false;
    //  console.log ("id del video "+video);
    return video;
  }

}
