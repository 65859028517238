import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechaseventos'
})

export class FechaseventosPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]) {
    if (value == args[0]){
      //console.log("iguales args");
    }else{
      //console.log("diferentes");
      value = "Del " + value + " al " + args[0];
    }
    return value;
  }
}


