import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
})
export class EventosComponent  implements OnInit, OnDestroy {

  noticias:any=[];

  constructor(public service: ServicioCarnavalService) { }

  ngOnInit() {
    this.service.setearpantallaFB("Eventos","EventosScreen");
    this.service.cargarEventos("all");
    //.subscribe(data =>{
    //  this.noticias = data;
    //  console.log(this.noticias);
    //});
    this.service.comprobarPubli("app-carnaval-coac-agenda");
  }

  ngOnDestroy(): void {
    this.service.observablePublicidad.unsubscribe();
    this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

}
