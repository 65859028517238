import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
import { ActivatedRoute,Router } from '@angular/router';
import { NavController, ModalController, Platform } from '@ionic/angular';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
//import { AdMob } from '@capacitor-community/admob';
//import { BannerAdOptions, BannerAdPluginEvents, BannerAdSize, BannerAdPosition } from '@capacitor-community/admob/dist/esm/banner';

@Component({
  selector: 'app-detalleagrupacion',
  templateUrl: 'detalleagrupacion.component.html',
  styleUrls: ['detalleagrupacion.component.scss'],
})

export class DetalleagrupacionComponent implements OnInit, OnDestroy  {
  @ViewChild('swiper')   swiperRef: ElementRef | undefined; 
  @ViewChild('swiper2')   swiperRef2: ElementRef | undefined; 
  swiperModules = [IonicSlides];
  private sub:any;
  agrupacion:any;
  nombre:any;
  id_grupo:any;
  i=0;
  public showLeftButton:boolean = false;
  public showRightButton:boolean = false;
  public url:string = "";

  constructor  (
    public navCtrl: NavController,
    private router:Router, private route: ActivatedRoute,
    private modalCrtl:ModalController,
    private platform:Platform,
    public service:ServicioCarnavalService,
    ) {
   // this.showBanner();
    }

  /*
    async showBanner (){
    try {
        AdMob.initialize({
        // requestTrackingAuthorization: true,
          initializeForTesting: false,
        });
        const options: BannerAdOptions ={
          adId:'ca-app-pub-6284242253541209/1659908862',
          adSize: BannerAdSize.ADAPTIVE_BANNER,
          position: BannerAdPosition.BOTTOM_CENTER,
          margin: 0,
          isTesting: true,
        };

        await AdMob.showBanner(options).then(() =>{
          console.log('banner ok')
        });
        AdMob.addListener(BannerAdPluginEvents.FailedToLoad,(error)=>{
          console.log(error.code);
          console.log(error.message);
        })
      } catch (e) { console.log('error', e); }
    }
*/
  ngOnInit() {
    this.service.setearpantallaFB("Agrupacion-detalle","Agrupacion-detalleScreen");
    this.sub = this.route.params.subscribe(params => {
      this.id_grupo = params['id_grupo']; // (+) converts string 'id' to a number
      // console.log("en detalle: "+this.id_grupo);
      this.service.cargarDetalleagrupacion(this.nombre,this.id_grupo) ;
    });
    this.service.comprobarPubli("app-carnaval-coac-agrupaciones");
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  irDetalleAgrupacion(nombre2:any,agrupacion2:any){
    // console.log ("nombre:",nombre2,"agrupacion:",agrupacion2);
    this.showLeftButton = false;
    this.showRightButton = false;
    this.service.fotos = [];
    this.service.cargarDetalleagrupacion(nombre2,agrupacion2);
  }

  irDetalleCronica(cronica:any){}

  regresar(){
    this.navCtrl.back();
  }

  /*
  irDetalleCronica(cronica:any){
    this.navCtrl.push(DetallePage, {cronica});
  }
 */

slidePrev(){
  if (!this.swiperRef?.nativeElement.swiper.isBeginning){
    this.swiperRef?.nativeElement.swiper.slidePrev();
    this.swiperRef2?.nativeElement.swiper.slidePrev();
  }
  this.slideChange();
}

slideNext(){
  if (!this.swiperRef?.nativeElement.swiper.isEnd){
    this.swiperRef?.nativeElement.swiper.slideNext();
    this.swiperRef2?.nativeElement.swiper.slideNext();
  }
  this.slideChange();
}

slideChange(){
  //console.log ("final:"+this.swiperRef?.nativeElement.swiper.isEnd.toString());
  //console.log ("inicio:"+this.swiperRef?.nativeElement.swiper.isBeginning.toString());
  if (this.swiperRef?.nativeElement.swiper.isEnd){
    this.showRightButton = false;
    this.service.boton = false;
  }else{
    this.showRightButton = true;
  };
  if (this.swiperRef?.nativeElement.swiper.isBeginning){
      this.showLeftButton = false;
  }else{
    this.showLeftButton = true;
  }

}
}