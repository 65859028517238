import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-carteles',
  templateUrl: './carteles.component.html',
  styleUrls: ['./carteles.component.scss'],
})
export class CartelesComponent implements OnInit, OnDestroy {

  //carteles:any=[];
  
  constructor(public service: ServicioCarnavalService) { }

  ngOnInit() {
    this.service.setearpantallaFB("Carteles","CartelesScreen");
    this.service.get_cartelescarnaval();
    this.service.comprobarPubli("app-carnaval-carteles");
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

}
