<ion-content class="modalGaleria">
  <div class="contenido">
    <ion-buttons slot="end">
      <ion-button (click)="cerrarModal()">
        <ion-icon  name="close-circle" size="large" float-end></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>


<div  *ngIf="directo.stream=='r-nimble'" class="radio">
  <video controls="" autoplay="" name="media"><source src="https://ondacadiztv.es:30443/ocr/directo/icecast.audio" type="audio/aac"></video>
</div>

<div *ngIf="directo.stream=='youtube'" id="videoContainer" #videoContainer xxxdata-shaka-player-container>
  <div *ngIf ="statuscookie !== 'deny'">
    <div class="visorvideo">
        <iframe id="videoocl" [src]="directo.link | safeurl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
  <div *ngIf = "statuscookie == 'deny'">
      <p class="mensaje">Su configuración de Cookies no permite ver los vídeos de Youtube</p>
  </div>
</div>

<div *ngIf="directo.stream=='nimble'" (click)="initPlayer()" id="videoContainer" #videoContainer xxxdata-shaka-player-container>
  <!--<video id="videoPlayer" #videoPlayer width="100%" height="100%"   poster="..\assets\carta.png">
  </video>-->
  <div class="video-player">
    <app-video-player  [videoLink]="videoSrc"></app-video-player>
  </div>
</div>

<div *ngIf="directo.stream=='twitch'" (click)="initPlayer()" id="videoContainer" #videoContainer xxxdata-shaka-player-container>
  <video id="videoPlayer" #videoPlayer width="100%" height="100%"   poster="..\assets\carta.png">
  </video>
</div>

<div *ngIf="directo.stream=='livestream'" (click)="initPlayer()" id="videoContainer" #videoContainer xxxdata-shaka-player-container>
  <video id="videoPlayer" #videoPlayer width="100%" height="100%"   poster="..\assets\carta.png">
  </video>
</div>

<div *ngIf="directo.stream=='flumotion'" (click)="initPlayer()" id="videoContainer" #videoContainer xxxdata-shaka-player-container>
  <video id="videoPlayer" #videoPlayer width="100%" height="100%"   poster="..\assets\carta.png">
  </video>
</div>

</ion-content>