import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
 
  transform(value: string, ...args: unknown[]): string {
    let nuevovalor:string = "";
    nuevovalor = value.replace('http://www.ivoox.com','https://www.ivoox.com');
    return nuevovalor;
  }
}
