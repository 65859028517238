import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'primeraimagen'
})

export class PrimeraimagenPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let nuevovalor:any[] = [];
    nuevovalor = value.split(',');
    return nuevovalor[0];
  }
}
