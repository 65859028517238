import { Component, Input, OnInit, ViewChild,ElementRef} from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
import { ModalController } from '@ionic/angular';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-modal-votaciones',
  templateUrl: './modal-votaciones.html',
  styleUrls: ['./modal-votaciones.scss'],
})
export class ModalVotacionesComponent  implements OnInit {
  @ViewChild('swiper')  swiperRef: ElementRef | undefined; 
  swiperModules = [IonicSlides];

  @Input() item:any;
  puntos:number = 0;
  uno:boolean = false;
  dos:boolean = false;
  tres:boolean = false;
  cuatro:boolean = false;
  cinco:boolean = false;


constructor(
  private modalCtrl:ModalController,
  public service: ServicioCarnavalService
  //private firebaseAnalytics:FirebaseAnalytics
  ){
  this.item=[];
}

ngOnInit(): void {
  // console.log(this.item);
  this.service.setearpantallaFB("Votaciones2","VotacionesScreen2");
  //  this.firebaseAnalytics.logEvent('page_view', {page: "modal-filtros"});
  let fecha=new Date();
  if (Date.parse(fecha.toISOString()) - this.service.ajustes.votos.fecha > 86400000){
    this.service.ajustes.votos.uno = false;
    this.service.ajustes.votos.dos = false;
    this.service.ajustes.votos.tres = false;
    this.service.ajustes.votos.cuatro = false;
    this.service.ajustes.votos.cinco = false;
    this.service.guardar_storage();
  }
  this.uno = this.service.ajustes.votos.uno;
  this.dos = this.service.ajustes.votos.dos;
  this.tres = this.service.ajustes.votos.tres;
  this.cuatro = this.service.ajustes.votos.cuatro;
  this.cinco = this.service.ajustes.votos.cinco;

}

ngAfterViewInit(){
  //this.swiperRef?.nativeElement.swiper.slideNext();

}

onViewDidLoad() {
 // this.firebaseAnalytics.logEvent('page_view', {page: "modalvideo"});
}

cerrarModal(){
    return this.modalCtrl.dismiss({'dismissed':true });
}

}
