import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { BrowserModule, provideClientHydration  } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { DirectosComponent } from './paginas/directos/directos.component';
import { HomeCarnavalComponent } from './paginas/home-carnaval/home-carnaval.component';
import { FiltroSesionesComponent } from './paginas/filtro-sesiones/filtro-sesiones';
import { SesionesComponent } from './paginas/sesiones/sesiones.component';
import { SesionesVideosComponent } from './paginas/sesiones-videos/sesiones-videos.component';
import { SesionesFotosComponent } from './paginas/sesiones-fotos/sesiones-fotos';
import { SesionesAudiosComponent } from './paginas/sesiones-audios/sesiones-audios';
import { AgrupacionesPage } from './paginas/agrupaciones/agrupaciones';
import { DetalleagrupacionComponent } from './paginas/detalleagrupacion/detalleagrupacion.component';
import { VideoPlayerComponent } from './paginas/video-player/video-player.component';
import { ShakaPlayerComponent } from './paginas/shaka-player/shaka-player.component';
import { CartelesComponent } from './paginas/carteles/carteles.component';
import { NoticiasComponent } from './paginas/noticias/noticias.component';
import { NoticiasDetalleComponent } from './paginas/noticias-detalle/noticias-detalle.component';
import { EventosComponent } from './paginas/eventos/eventos.component';
import { EventosDetalleComponent } from './paginas/eventos-detalle/eventos-detalle.component';
import { MasCarnavalComponent } from './paginas/mas-carnaval/mas-carnaval.component';
import { ProgramasTvComponent } from './paginas/programas-tv/programas-tv';
import { RankingComponent } from './paginas/ranking/ranking';
import { VotacionesComponent } from './paginas/votaciones/votaciones';
import { EnlacesComponent } from './paginas/enlaces/enlaces';
import { ModalVideoPage } from './paginas/modalVideo/modalVideo';
import { ModalImagenComponent } from './paginas/modal-imagen/modal-imagen';
import { ProgramacionComponent } from './paginas/programacion/programacion';
import { ModalVotacionesComponent } from './paginas/modal-votaciones/modal-votaciones';
import { ModalMensajeComponent } from './paginas/modal-mensaje/modal-mensaje';
import { ModalPubliComponent } from './paginas/modal-publi/modal-publi';
import { TipoContenidoCofPipe } from './pipes/tipo-contenido-cof.pipe';
import { FiltrodiassPipe } from './pipes/filtrodiass.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FechaPipe } from './pipes/fecha.pipe';
import { RecortaParrafoPipe } from './pipes/recorta-parrafo.pipe';
import { SafeurlPipe } from './pipes/safeurl.pipe';
import { FechaseventosPipe } from './pipes/fechaseventos.pipe';
import { FechasfiltroPipe } from './pipes/fechasfiltro.pipe';
import { SesionPipe } from './pipes/sesion.pipe';
import { ImagenPipe, AgnoPipe, CategoriaPipe } from './pipes/imagen.pipe';
import { MiniaturavideoPipe } from './pipes/miniaturavideo.pipe';
import { PasePipe } from './pipes/pase.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { TcronicaPipe } from './pipes/tcronica.pipe';
import { PrimeraimagenPipe } from './pipes/primeraimagen.pipe';

/*
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
*/
@NgModule({ declarations: [
        AppComponent,
        DirectosComponent,
        HomeCarnavalComponent,
        FiltroSesionesComponent,
        SesionesComponent,
        SesionesVideosComponent,
        SesionesFotosComponent,
        SesionesAudiosComponent,
        AgrupacionesPage,
        DetalleagrupacionComponent,
        VideoPlayerComponent,
        ShakaPlayerComponent,
        CartelesComponent,
        NoticiasComponent,
        NoticiasDetalleComponent,
        EventosComponent,
        EventosDetalleComponent,
        MasCarnavalComponent,
        ProgramasTvComponent,
        RankingComponent,
        VotacionesComponent,
        EnlacesComponent,
        ProgramacionComponent,
        ModalVideoPage,
        ModalImagenComponent,
        ModalVotacionesComponent,
        ModalMensajeComponent,
        ModalPubliComponent,
        
        TipoContenidoCofPipe,
        FiltrodiassPipe,
        FilterPipe,
        FechaPipe,
        RecortaParrafoPipe,
        SafeurlPipe,
        FechaseventosPipe,
        FechasfiltroPipe,
        SesionPipe,
        ImagenPipe, AgnoPipe, CategoriaPipe,
        MiniaturavideoPipe,
        PasePipe,
        SecurePipe,
        TcronicaPipe,
        PrimeraimagenPipe
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule],
    providers: [
                provideHttpClient(withFetch()),
   
                { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ] 
})
// quito esta linea para que ponga bien las metaetiquetas { provide: LocationStrategy, useClass: HashLocationStrategy },        
// ScreenOrientation,
        // provideClientHydration()
    
export class AppModule {}
