import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-sesiones-videos',
  templateUrl: './sesiones-videos.component.html',
  styleUrls: ['./sesiones-videos.component.scss'],
})
export class SesionesVideosComponent  implements OnInit, OnDestroy {

  constructor(public service: ServicioCarnavalService, private readonly titleService: Title) { }

  ngOnInit() {
    this.service.setearpantallaFB("Vídeos-sesión","Vídeos-sesiónScreen");
    // if (this.service.filtrosa[0] !== undefined){
    //   this.service.cargarVideoscoac(this.service.filtrosa[0].agno+this.service.filtrosa[0].mes+this.service.filtrosa[0].dia,"all",this.categoria,"");
    //}
    this.service.comprobarPubli("app-carnaval-carta-videoscoac");
    // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
  }

  ngOnDestroy(): void {
   // this.service.observablePublicidad.unsubscribe();
   // this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }
  
}
