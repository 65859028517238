import { Pipe, PipeTransform } from '@angular/core';
const host_carnaval="https://contenidos.ondacadiz.es";  //carnaval.ondacadiz.es

@Pipe({
  name: 'imagen'
})

export class ImagenPipe implements PipeTransform {
  transform(value:string, ...args: unknown[]): string {
    if (value == host_carnaval) {
      value = '/assets/imgs/logo-oc-positivo-blanco.png';
    }
  return value;
  }
}

@Pipe({
  name: 'agno',
})
export class AgnoPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return value.slice(5,5);
  }
}

@Pipe({
  name: 'categoria',
})
export class CategoriaPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    let cad = "";
    if (value == 'Adulto'){
      cad = "";}
    else {cad = " " + value;}
    return cad;
  }
}
