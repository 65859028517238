import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-votaciones',
  templateUrl: './votaciones.html',
  styleUrls: ['./votaciones.scss'],
})
export class VotacionesComponent  implements OnInit, OnDestroy {
  
  rango:any;
  tipoagrupacion:any;
  
  constructor(public service: ServicioCarnavalService, private readonly titleService: Title) {
   }

  ngOnInit() {
    this.service.setearpantallaFB("Votaciones2","VotacionesScreen2");
    this.service.cargarItemsparavotos();
    console.log(this.service.itemsparavotos);
    this.service.comprobarPubli("app-carnaval-participa-votaciones");
    // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }
  
}
