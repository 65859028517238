<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Ficha agrupación</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div *ngFor="let grupo2 of service.detalleagrupacion">
    <ion-card *ngFor="let grupo of grupo2.nodes">
    <ion-grid>
      <ion-row>
        <ion-col size-md="7" size-sm="12" size-xs="12">
          <img [src]="grupo.node.fotos | imagen" (click) = "service.verImagengaleria(service.fotos,0)"/>
        </ion-col>
        <ion-col size-md="5" size-sm="12" class="datos">
          <h4>{{grupo.node.modalidad}} {{grupo.node.categoria | categoria}}</h4>
          <h1 text-center>{{grupo.node.titulo}}</h1>
          <p><span class="tit">Año:</span>{{grupo.node.year}}</p>
          <p><span class="tit">Ciudad:</span>{{grupo.node.localidad}}</p>
          <p><span class="tit">Letra:</span><span class="bloque">{{grupo.node.autorletra}}</span></p>
          <p *ngIf="grupo.node.textauthor2"><span class="tit"></span>{{grupo.node.textauthor2}}</p>
          <p *ngIf="grupo.node.textauthor3"><span class="tit"></span>{{grupo.node.textauthor3}}</p>
          <p><span class="tit">Música:</span><span class="bloque">{{grupo.node.autormusica}}</span></p>
          <p *ngIf="grupo.node.musicauthor2"><span class="tit"></span>{{grupo.node.musicauthor2}}</p>
          <p *ngIf="grupo.node.musicauthor3"><span class="tit"></span>{{grupo.node.musicauthor3}}</p>
          <p><span class="tit">Director:</span><span class="bloque">{{grupo.node.director}}</span></p>
          <p><span class="tit">Repr. legal:</span>{{grupo.node.representante}}</p>
        </ion-col>
      </ion-row>
      <br/>
      
      <ion-row class="slidezone a">   
        <ion-col class="col-with-arrow" (click)="slidePrev()" size="1">
          <ion-icon *ngIf="showLeftButton" class="arrow-back" name="chevron-back-outline" aria-hidden="true"></ion-icon>
        </ion-col>
        <ion-col no-padding size="10" >
          <div *ngFor="let grupo3 of service.detalleagrupacion_contenidos">
          <swiper-container  #swiper (slidechangetransitionend)="slideChange()" [slidesPerView]="2" [loop]="false">
            <swiper-slide (click)="service.openVideo(videos.node.link)" *ngFor="let videos of grupo3.nodes">
                <img class="img1" col-3 item-start [src]="videos.node.link| miniaturavideo ">
                <ion-label class="etiqueta ion-text-center">{{videos.node.titulo | pase }}</ion-label>
            </swiper-slide>
          </swiper-container>
          </div>
        </ion-col>
        <ion-col class="col-with-arrow" (click)="slideNext()" size="1">
          <ion-icon *ngIf="showRightButton || service.boton" class="arrow-forward" name="chevron-forward-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <br/>
      <ion-row  *ngIf="service.hayaudio">
        <ion-col no-padding col-12 class="slidezone d">
          <h4>Audios</h4>
          <ion-grid>
            <ion-row *ngFor="let grupo3 of service.detalleagrupacion_contenidos">
                <ion-col size-md="6" size-sm="12"  size-xs="12" *ngFor="let videos of grupo3.nodes">
                    <ion-label class="etiqueta ion-text-center">{{videos.node.titulo|pase}}</ion-label>
                    <audio controls src={{videos.node.audio|secure}}>No se puede reproducir este audio</audio>
                </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
      <br/>
      <ion-row *ngIf="service.haycronica" class="slidezone b">
        <ion-col size="12"><h4>Crónicas</h4></ion-col>
        <ion-col class="col-with-arrow" (click)="slidePrev()" size="1">
          <ion-icon *ngIf="showLeftButton" class="arrow-back" name="chevron-back-outline" aria-hidden="true"></ion-icon>
        </ion-col>
        <ion-col no-padding size="10">
          <div *ngFor="let grupo4 of service.detalleagrupacion_contenidos">
          <swiper-container  #swiper2 (slidechangetransitionend)="slideChange()" [slidesPerView]="2" [loop]="false">
            <swiper-slide (click)="irDetalleCronica(cronica.node)" *ngFor="let cronica of grupo4.nodes">
              <ion-label class="ion-text-center">{{cronica.node.titulo|pase}} - {{cronica.node.nodedate | fecha}}</ion-label>
              <img class="img1" item-start [src]="cronica.node.foto_medio|primeraimagen">
              <!--p color="secundary" text-left>{{cronica.node.nodedate}}</p-->               
              <h4>{{cronica.node.descripcion|tcronica}}</h4>
            </swiper-slide>
          </swiper-container>
          </div>
        </ion-col>
        <ion-col class="col-with-arrow" (click)="slideNext()" size="1">
          <ion-icon *ngIf="showRightButton || service.boton" class="arrow-forward" name="chevron-forward-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <br/>
      <ion-row>
          <ion-col  *ngIf="service.hayfotos" no-padding col-12 class="slidezone c">
            <h4>Galería de fotos</h4>
            <div class="galeria">
              <ion-img item-start (click)="service.verImagengaleria(service.fotos, a+i)"  [src]="galeria" class="galeria_img" *ngFor="let galeria of service.fotos | slice:i:i+10; let a = index;"></ion-img>
            </div>
            <br/>
            <ion-buttons>
            <ion-button  fill="solid" [disabled]="i<=0" (click)="i=i-10;"><ion-icon class="arrow-back" name="chevron-back-outline"></ion-icon> Ant</ion-button>
            <ion-button  fill="solid" [disabled]="i>=service.fotos.length-10" (click)="i=i+10;">Sig <ion-icon class="arrow-forward" name="chevron-forward-outline"></ion-icon></ion-button>
            </ion-buttons>
          </ion-col>
      </ion-row>      
      <br/>

        <h4 *ngIf="grupo.node.agrupacion2023 || grupo.node.agrupacion2022 || grupo.node.agrupacion2020 || grupo.node.agrupacion2019 || grupo.node.agrupacion2018 || grupo.node.agrupacion2017 ||grupo.node.agrupacion2016
      || grupo.node.agrupacion2015 || grupo.node.agrupacion2014 || grupo.node.agrupacion2013 ||grupo.node.agrupacion2012
      || grupo.node.agrupacion2011 || grupo.node.agrupacion2010 || grupo.node.agrupacion2009">Otros años fueron</h4>

      <ion-row class="anterior">
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2023" (click)="irDetalleAgrupacion(grupo.node.agrupacion2023,grupo.node.agrupacion2023_nid);"><span class="enlace"><span class="enlace-label">2023: </span>{{grupo.node.agrupacion2023}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2022" (click)="irDetalleAgrupacion(grupo.node.agrupacion2022,grupo.node.agrupacion2022_nid);"><span class="enlace"><span class="enlace-label">2022: </span>{{grupo.node.agrupacion2022}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2020" (click)="irDetalleAgrupacion(grupo.node.agrupacion2020,grupo.node.agrupacion2020_nid);"><span class="enlace"><span class="enlace-label">2020: </span>{{grupo.node.agrupacion2020}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2019" (click)="irDetalleAgrupacion(grupo.node.agrupacion2019,grupo.node.agrupacion2019_nid);"><span class="enlace"><span class="enlace-label">2019: </span>{{grupo.node.agrupacion2019}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2018" (click)="irDetalleAgrupacion(grupo.node.agrupacion2018,grupo.node.agrupacion2018_nid);"><span class="enlace"><span class="enlace-label">2018: </span>{{grupo.node.agrupacion2018}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2017" (click)="irDetalleAgrupacion(grupo.node.agrupacion2017,grupo.node.agrupacion2017_nid);"><span class="enlace"><span class="enlace-label">2017: </span>{{grupo.node.agrupacion2017}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2016" (click)="irDetalleAgrupacion(grupo.node.agrupacion2016,grupo.node.agrupacion2016_nid);"><span class="enlace"><span class="enlace-label">2016: </span>{{grupo.node.agrupacion2016}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2015" (click)="irDetalleAgrupacion(grupo.node.agrupacion2015,grupo.node.agrupacion2015_nid);"><span class="enlace"><span class="enlace-label">2015: </span>{{grupo.node.agrupacion2015}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2014" (click)="irDetalleAgrupacion(grupo.node.agrupacion2014,grupo.node.agrupacion2014_nid);"><span class="enlace"><span class="enlace-label">2014: </span>{{grupo.node.agrupacion2014}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2013" (click)="irDetalleAgrupacion(grupo.node.agrupacion2013,grupo.node.agrupacion2013_nid);"><span class="enlace"><span class="enlace-label">2013: </span>{{grupo.node.agrupacion2013}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2012" (click)="irDetalleAgrupacion(grupo.node.agrupacion2012,grupo.node.agrupacion2012_nid);"><span class="enlace"><span class="enlace-label">2012: </span>{{grupo.node.agrupacion2012}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2011" (click)="irDetalleAgrupacion(grupo.node.agrupacion2011,grupo.node.agrupacion2011_nid);"><span class="enlace"><span class="enlace-label">2011: </span>{{grupo.node.agrupacion2011}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2010" (click)="irDetalleAgrupacion(grupo.node.agrupacion2010,grupo.node.agrupacion2010_nid);"><span class="enlace"><span class="enlace-label">2010: </span>{{grupo.node.agrupacion2010}}</span></ion-col>
        <ion-col size-md="6" size-sm="12" size-xs="12" *ngIf="grupo.node.agrupacion2009" (click)="irDetalleAgrupacion(grupo.node.agrupacion2009,grupo.node.agrupacion2009_nid);"><span class="enlace"><span class="enlace-label">2009: </span>{{grupo.node.agrupacion2009}}</span></ion-col>
      </ion-row>
    </ion-grid>
    <ion-button (click)="this.service.compartir()">Compartir</ion-button>
    <hr>

    <!--script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script-->
    <!-- G1 adaptable -->
     <!--
    <ins class="adsbygoogle"
         style="display:block"
         data-ad-client="ca-pub-3224587814527712"
         data-ad-slot="3189896279"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
    <script>
         (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
  -->
    <br/>
    </ion-card>
    <div class="regre">
      <ion-buttons slot="secondary">
        <ion-button fill="solid" expand="block" (click)="regresar()">
              Regresar
        </ion-button>
      </ion-buttons>
    </div>
    <br/>
  </div>
</ion-content>
