import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-directos',
  templateUrl: './directos.component.html',
  styleUrls: ['./directos.component.scss']
})
export class DirectosComponent implements OnInit {

  private sub:any;
  nid:any;
  directos:any;

  constructor(private _sanitizer: DomSanitizer, private route: ActivatedRoute,
    public service: ServicioCarnavalService, private readonly titleService: Title) {

  }

  ngOnInit(): void {
    this.service.setearpantallaFB("Directos","DirectosHome");
    this.service.cargarDirectos("all","1").subscribe(resp3 => {
      this.directos = resp3.nodes;
      //console.log (this.directos);
    });
    
    //console.log(this.directo.link);
    //console.log(this.directo.stream);
  }

}
