<ion-header [translucent]="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>Noticias</ion-title>
      <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
    </ion-toolbar>
</ion-header>
<ion-content>

  <div class="row content">
    <ion-buttons class="aspa" slot="end">
      <ion-button (click)="regresar()">
        <ion-icon  name="close-circle" size="large" float-end></ion-icon>
      </ion-button>
    </ion-buttons>
      <ion-card *ngFor="let detalle of service.noticia_detalle_item">
        <ion-grid>
          <ion-row>
            <ion-col size-md="7" size-sm="12" size-xs="12">
              <ion-img [src]='detalle.node.mainimagebig' [alt]="detalle.node.title | slice:20"></ion-img>
            </ion-col>
            <ion-col size-md="5" size-sm="12" class="cuerpo">
              <p>{{detalle.node.nodedate}}</p>
              <h2>{{detalle.node.title}}</h2>
              <p>{{detalle.node.subtitle}}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-md="12" size-sm="12" class="cuerpo">
              <div *ngIf="detalle.node.link" class="video" (click)="service.openVideo(detalle.node.link)">
                <img class="img1" col-3 item-start [src]="detalle.node.link| miniaturavideo ">
                <ion-icon class="play" name="play-circle" ng-reflect-ios="play-circle-outline" ng-reflect-md="play-circle-sharp"></ion-icon>
              </div>
              <div [innerHTML]="detalle.node.description">
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
      <div class="regre">
        <ion-buttons slot="secondary">
          <ion-button fill="solid" expand="block" (click)="regresar()">
                Regresar
          </ion-button>
        </ion-buttons>
      </div>
      <br/>
    </div>
</ion-content>