<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Noticias</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <ion-item>
      <ion-grid>
        <ion-row>
          <ion-col size-md="8" size-sm="8" size-xs="6"></ion-col>
          <ion-col size-md="4" size-sm="4" size-xs="6">
            <ion-searchbar color="light" class="ion-no-padding" [(ngModel)]="filtro" (change)="filtro=filtrado.value;" #filtrado mode="ios" [placeholder]="'Buscar '">
            </ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <ion-grid>
      <ion-row class="carta">
        <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12" *ngFor="let item4 of service.noticia_item | filter:filtro">
          <ion-card (click)="service.ir_a_noticia(item4.node)">
            <ion-row>
              <ion-col>
                <ion-img alt="{{item4.node.title}}" [src]="item4.node.mainimage"></ion-img>
              </ion-col>
              <ion-col>
                <ion-label>
                  <span class="etiqueta">{{item4.node.nodedate}}</span>
                  <p><br/></p>
                  <h2>{{item4.node.title}}</h2>
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>