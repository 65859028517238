<ion-header [translucent]="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>Agenda detalle</ion-title>
      <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
    </ion-toolbar>
</ion-header>
<ion-content>

  <div class="row content">
    <ion-buttons class="aspa" slot="end">
      <ion-button (click)="regresar()">
        <ion-icon  name="close-circle" size="large" float-end></ion-icon>
      </ion-button>
    </ion-buttons>
        <ion-card *ngFor="let item4 of service.eventos_detalle_item">
          <h3>{{ item4.node.title}}</h3>
          <p class="etiqueta">{{ item4.node.fechadefinitiva }} {{item4.node.horadefinitiva }}</p>
          <ion-grid>
            <ion-row>
              <ion-col size-md="7" size-sm="12" size-xs="12">
                <ion-img alt="{{item4.node.title}}" [src]="item4.node.thumbimage"></ion-img>
              </ion-col>
              <ion-col size-md="5" size-sm="12" class="cuerpo">    
                <p *ngIf="item4.node.price"><ion-label>Precio: </ion-label>{{ item4.node.price}}</p>
                <p *ngIf="item4.node.location"><ion-label>Dirección: </ion-label>{{ item4.node.location }}</p>
                <p *ngIf="item4.node.address"><ion-label>Dirección: </ion-label>{{ item4.node.address }}</p>
              </ion-col> 
            </ion-row>
            <ion-row>
              <div [innerHTML]="item4.node.descripcion_completa"></div>  
            </ion-row>
          </ion-grid>      
        </ion-card>
      <div class="regre">
        <ion-buttons slot="secondary">
          <ion-button fill="solid" expand="block" (click)="regresar()">
                Regresar
          </ion-button>
        </ion-buttons>
      </div>
      <br/>
    </div>
</ion-content>