<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Enlaces de interés</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">

    <ion-list>
      <div *ngFor="let enlaces2 of this.service.enlaces">   
        <ion-item (click)="service.iraEnlace(enlaces2.node.url)"> 
          <h4  text-left class="enlaces">{{enlaces2.node.titulo}}</h4>
          <ion-col slot="end" size-md="1" size-sm="1" size-xs="1">
            <img item-end class="icono" src="/assets/img/icon_nav_adelante.png">  
          </ion-col>
      </ion-item>
    </div>
    </ion-list>
  </div>
</ion-content>
