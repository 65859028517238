import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'page-agrupaciones',
  templateUrl: './agrupaciones.html',
  styleUrls: ['./agrupaciones.scss'],
})

export class AgrupacionesPage implements OnInit, OnDestroy {

  //filtrostipo:number = 1;
  //fechas:any ="2023";
  //categoria:string = "all";
  //tipoagrupacion:string = "all";

  constructor(public service: ServicioCarnavalService, private route: ActivatedRoute, private router:Router,
    ) { 
      this.service.cargarFiltrosAgrupaciones();
     }

  ngOnInit() {
    this.service.setearpantallaFB("Agrupaciones","AgrupacionesScreen");
    this.service.cargarAgrupaciones("selected","all");
    // console.log(this.service.agrupaciones);
    this.service.comprobarPubli("app-carnaval-coac-agrupaciones");
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  ionViewDidLeave(){
    //this.service.pararPubli();
  }

  irDetalleAgrupacion(nombre:any,agrupacion:any){
    // console.log("datos->",nombre,"-",agrupacion);
    this.router.navigate(['/carnaval/agrupacion/'+agrupacion]);
  }

  filterData (valor:any,tipo:any){
    // console.log(valor, tipo);
    this.service.cargarAgrupaciones(valor,tipo);
  }

  // eligeTipoagrupacion(){
  //  if (this.tipoagrupacion == 'all'){
  //    this.tipoagrupacion = 'Coros';
  //  }
  // }

}
