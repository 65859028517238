import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-enlaces',
  templateUrl: './enlaces.html',
  styleUrls: ['./enlaces.css']
})

export class EnlacesComponent implements OnInit, OnDestroy {

  private sub:any;
  nid:any;  

  constructor(private _sanitizer: DomSanitizer, private route: ActivatedRoute,
    public service: ServicioCarnavalService, private readonly titleService: Title) {
  }

  ngOnInit(): void {
    // this.firebaseAnalytics.logEvent('page_view', {page: "enlaces-de-interes"});
    this.service.setearpantallaFB("Enlaces","EnlacesScreen");
    const TIEMPO_CACHE = 10000;
    let actual=new Date();
    if (localStorage.getItem('enlaces')){
       let enlace = JSON.parse( localStorage.getItem("enlaces") || '{}');
       console.log("hora:"+enlace[0].hora);
       console.log("diferencia:"+(Date.parse(actual.toISOString()) - enlace[0].hora));
       if ((Date.parse(actual.toISOString()) - enlace[0].hora) > TIEMPO_CACHE){
         this.service.cargarEnlaces();
         console.log("1");
         console.log(this.service.enlaces);
       }
    }
    else{
         this.service.cargarEnlaces();
         console.log("2");
         console.log(this.service.enlaces);
     }
    this.service.comprobarPubli("app-carnaval-enlaces");

  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

}
