import { Component, OnInit, ElementRef, Renderer2, AfterViewInit, OnDestroy} from '@angular/core';
import { ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Swiper } from 'swiper';


@Component({
  selector: 'app-filtro-sesiones',
  templateUrl: './filtro-sesiones.html',
  styleUrls: ['./filtro-sesiones.scss'],
})
export class FiltroSesionesComponent  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('swipera', { static: false }) swiperRefa?: ElementRef;
  @ViewChild('swiperj', { static: false }) swiperRefj?: ElementRef;
  @ViewChild('swiperi', { static: false }) swiperRefi?: ElementRef;

  clicadoa:number=1000;
  clicadoj:number=1000;
  clicadoi:number=1000;
  categoria:any ="adulto";
  fecha:any;
  sesiones:any;
  index: any;
  swiperParams1 = {
    slidesPerView: 2,
    initialSlide :1,
    breakpoints:{
      370: {slidesPerView: 3,spaceBetween: 4},
      480: {slidesPerView: 4,spaceBetween: 5},
      640: {slidesPerView: 5,spaceBetween: 10},
      947: {slidesPerView: 8,spaceBetween: 15},
      1040: {slidesPerView: 10,spaceBetween: 20},
    },
    on:{ init(){}, },
  };

  constructor(private route: ActivatedRoute, private router:Router, public service: ServicioCarnavalService,
     private el: ElementRef, private renderer: Renderer2) { 
     }

  ngOnDestroy() {
    this.destroySwiperInstance(this.swiperRefa);
    this.destroySwiperInstance(this.swiperRefj);
    this.destroySwiperInstance(this.swiperRefi);
  }
    
  private destroySwiperInstance(swiperRef?: ElementRef) {
    if (swiperRef?.nativeElement.swiper) {
      swiperRef.nativeElement.swiper.destroy(true, true);
    }
  }

  ngOnInit() {  
    //this.service.cargarFiltrosSesiones().subscribe(resp3 => {
    //  this.service.cargarVideoscoac(this.service.filtrosa[0].agno+this.service.filtrosa[0].mes+this.service.filtrosa[0].dia,"all",this.categoria,""); 
    //  this.onChangecategoria("adulto");  
    //});
    this.index = this.service.buscarindice(this.categoria);
    //this.service.cargarVideoscoac(this.service.ajustes.filtrosa[this.index].agno+this.service.ajustes.filtrosa[this.index].mes+this.service.ajustes.filtrosa[this.index].dia,"all",this.categoria,"");
    this.onChangefecha(this.service.ajustes.filtrosa[this.index].agno+this.service.ajustes.filtrosa[this.index].mes+this.service.ajustes.filtrosa[this.index].dia, this.categoria, this.index); 
    // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
    
  }

  ngAfterViewInit(){
    if (this.swiperRefa?.nativeElement) {
      Object.assign(this.swiperRefa.nativeElement, this.swiperParams1);
    }
    if (this.swiperRefj?.nativeElement) {
      Object.assign(this.swiperRefj.nativeElement, this.swiperParams1);
    }
    if (this.swiperRefi?.nativeElement) {
      Object.assign(this.swiperRefi.nativeElement, this.swiperParams1);
    }
    setTimeout(()=> {
    if (this.swiperRefa && this.swiperRefa.nativeElement.swiper) {
      const swiperInstance = this.swiperRefa.nativeElement.swiper;
      swiperInstance.slideTo(this.index, 2000);
        const currentSlide = swiperInstance.slides[this.index];
        if (currentSlide) {
          this.renderer.addClass(currentSlide, 'clicado');
          this.clicadoa = this.index;
        }
      }
    }, 100);
  }

  onChangefecha(valor2:string, cat:string, index:number){
    this.service.seteareventoFB("filtro_de_contenido",valor2,cat);
    //console.log ("fecha"+valor2+"categoria"+cat);
    //console.log('Slide clicked:', index);
    // Acceder a propiedades del Swiper usando la referencia y el índice
    switch(cat) {
      case "adulto":
        if (this.swiperRefa && this.swiperRefa.nativeElement.swiper) {
          const swiperInstance = this.swiperRefa.nativeElement.swiper;
          const activeIndex = swiperInstance.activeIndex; 
          const slides = swiperInstance.slides;
          const clickedSlide = slides[index]; 
          //console.log('Active index a:', activeIndex);
          //console.log('Clicked slide a:', clickedSlide); 
          // ... puedes acceder a otras propiedades del Swiper aquí
          if (this.clicadoa!==1000){
            this.renderer.removeClass(swiperInstance.slides[this.clicadoa], 'clicado');
          }
          swiperInstance.slideTo(index,2000);
          this.renderer.addClass(swiperInstance.slides[index], 'clicado');
          this.clicadoa=index;
        }
        break;
      case "juvenil":
        if (this.swiperRefj && this.swiperRefj.nativeElement.swiper) {
          const swiperInstance = this.swiperRefj.nativeElement.swiper;
          const activeIndex = swiperInstance.activeIndex; 
          const slides = swiperInstance.slides;
          const clickedSlide = slides[index]; 
          if (this.clicadoj!==1000){
            this.renderer.removeClass(swiperInstance.slides[this.clicadoj], 'clicado');
          }
          swiperInstance.slideTo(index,2000);
          this.renderer.addClass(swiperInstance.slides[index], 'clicado');
          this.clicadoj=index;
        }
        break;
        case "infantil":
          if (this.swiperRefi && this.swiperRefi.nativeElement.swiper) {
            const swiperInstance = this.swiperRefi.nativeElement.swiper;
            const activeIndex = swiperInstance.activeIndex; 
            const slides = swiperInstance.slides;
            const clickedSlide = slides[index]; 
            if (this.clicadoi!==1000){
              this.renderer.removeClass(swiperInstance.slides[this.clicadoi], 'clicado');
            }
            swiperInstance.slideTo(index,2000);
            this.renderer.addClass(swiperInstance.slides[index], 'clicado');
            this.clicadoi=index;
          }
          break;
        }
    let valor3=valor2.replace('-','');
    let valor4=valor3.replace('-','');
    this.service.cargarVideoscoac(valor4,"all",cat,"");
    this.service.cargarSesiones(cat,valor2);//.subscribe(resp3 => { });  
  }

  onChangecategoria(valor:string){
   valor=valor.toLowerCase();
   this.service.seteareventoFB("filtro_de_contenido",'inicio',valor);
   const swiperEla2 = this.el.nativeElement.querySelector('#swipera');
   const swiperElj2 = this.el.nativeElement.querySelector('#swiperj');
   const swiperEli2 = this.el.nativeElement.querySelector('#swiperi');
    switch(valor) {    
      case "adulto":
        //  this.service.filtros = this.service.filtrosa;
        if (this.clicadoa !== 1000){
          this.index = this.clicadoa
        }else{
          this.index = this.service.buscarindice(valor);
        }
        this.service.cargarVideoscoac(this.service.ajustes.filtrosa[this.index].agno+this.service.ajustes.filtrosa[this.index].mes+this.service.ajustes.filtrosa[this.index].dia,"all",valor,""); 
        this.service.cargarSesiones("adulto",this.service.ajustes.filtrosa[this.index].agno+"-"+this.service.ajustes.filtrosa[this.index].mes+"-"+this.service.ajustes.filtrosa[this.index].dia); //.subscribe(resp3 => { });
        this.renderer.removeClass(swiperEla2, 'ocultar');
        this.renderer.addClass(swiperElj2, 'ocultar');
        this.renderer.addClass(swiperEli2, 'ocultar');

        setTimeout(()=> {
          if (this.swiperRefa && this.swiperRefa.nativeElement.swiper) {
            const swiperInstance = this.swiperRefa.nativeElement.swiper;
            swiperInstance.slideTo(this.index, 2000);
              const currentSlide = swiperInstance.slides[this.index];
              if (currentSlide) {
                this.renderer.addClass(currentSlide, 'clicado');
                this.clicadoa = this.index;
              }
            }
          }, 100);

        break;
      case "juvenil":
        // this.service.filtros = this.service.filtrosj;
        if (this.clicadoj !== 1000){
          this.index = this.clicadoj
        }else{
          this.index = this.service.buscarindice(valor);
        }
        this.service.cargarVideoscoac(this.service.ajustes.filtrosj[this.index].agno+this.service.ajustes.filtrosj[this.index].mes+this.service.ajustes.filtrosj[this.index].dia,"all",valor,""); 
        this.service.cargarSesiones("juvenil",this.service.ajustes.filtrosj[this.index].agno+"-"+this.service.ajustes.filtrosj[this.index].mes+"-"+this.service.ajustes.filtrosj[this.index].dia); //.subscribe(resp3 => { });
        this.renderer.addClass(swiperEla2, 'ocultar');
        this.renderer.removeClass(swiperElj2, 'ocultar');
        this.renderer.addClass(swiperEli2, 'ocultar');

        setTimeout(()=> {
          if (this.swiperRefj && this.swiperRefj.nativeElement.swiper) {
            const swiperInstance = this.swiperRefj.nativeElement.swiper;
            swiperInstance.slideTo(this.index, 2000);
              const currentSlide = swiperInstance.slides[this.index];
              if (currentSlide) {
                this.renderer.addClass(currentSlide, 'clicado');
                this.clicadoj = this.index;
              }
            }
          }, 100);

        break;
      case "infantil":
        // this.service.filtros = this.service.filtrosi;
        if (this.clicadoi !== 1000){
          this.index = this.clicadoi
        }else{
          this.index = this.service.buscarindice(valor);
        }
        this.service.cargarVideoscoac(this.service.ajustes.filtrosi[this.index].agno+this.service.ajustes.filtrosi[this.index].mes+this.service.ajustes.filtrosi[this.index].dia,"all",valor,""); 
        this.service.cargarSesiones("infantil",this.service.ajustes.filtrosi[this.index].agno+"-"+this.service.ajustes.filtrosi[this.index].mes+"-"+this.service.ajustes.filtrosi[this.index].dia); //.subscribe(resp3 => { });
        this.renderer.addClass(swiperEla2, 'ocultar');
        this.renderer.addClass(swiperElj2, 'ocultar');
        this.renderer.removeClass(swiperEli2, 'ocultar');

        setTimeout(()=> {
          if (this.swiperRefi && this.swiperRefi.nativeElement.swiper) {
            const swiperInstance = this.swiperRefi.nativeElement.swiper;
            swiperInstance.slideTo(this.index, 2000);
              const currentSlide = swiperInstance.slides[this.index];
              if (currentSlide) {
                this.renderer.addClass(currentSlide, 'clicado');
                this.clicadoi = this.index;
              }
            }
          }, 100);

        break;          
      default:
        this.service.filtros = this.service.filtrosr;
    }
  }

  miraindice(i:number){
   /*  
     if (this.swiperRef?.nativeElement.swiper.clickedIndex == undefined){
       if (this.swiperRef !== undefined){
         this.swiperRef.nativeElement.swiper.clickedIndex = this.index;
       }
     }
     if (this.swiperRef?.nativeElement.swiper.clickedIndex == i){
       //console.log("indice:"+this.swiperRef?.nativeElement.swiper.clickedIndex);
       return true;
     }
     else{return false; }
   */
  }

  onSlideChange(e: any, cat:string) {
    /*
      const nombremeses = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
      const activeIndex = e?.detail?.[0]?.activeIndex;
      const clickedIndex = e?.detail?.[0];
      console.log("active"+activeIndex);
      console.log(clickedIndex);
      const mesagno = e?.detail?.[0]?.slides[activeIndex].childNodes[0].innerHTML; //Feb'24
      const dia=e?.detail?.[0]?.slides[activeIndex].childNodes[1].innerHTML;
      const partes = mesagno.split('\'');
      const mesAbreviado = partes[0].trim();
      const anio = 2000+parseInt(partes[1]);
      const indiceMes = nombremeses.indexOf(mesAbreviado);
      const mesConCero = (indiceMes + 1).toString().padStart(2, '0');
      console.log(anio+"-"+mesConCero+"-"+dia);
    */
  }

}
