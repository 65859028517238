<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Carnaval de Cádiz</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <div>
      <h5 class="texto">
      <span>Carnaval de Cádiz, de Interés Turístico Internacional</span><br/>
      <span>{{service.ajustes.fechacarnaval}}</span>
      </h5>
    </div>
    <div *ngIf="service.sesion_agrupacion.length > 0" class="slidezone s">
      <h4>Hoy en el Falla</h4>
      <div *ngFor="let sesion of service.sesion_agrupacion; let i = index">
        <ion-item (click)="irDetalleAgrupacion('all',service.id_grupos[i]);">
          <ion-label>{{service.hora1[i]}}  {{sesion | sesion:2 }}
          <h2>{{sesion | sesion:1 }}</h2></ion-label>
          <!--ion-icon slot="end" class="arrow-forward" name="add-outline"></ion-icon-->
        </ion-item>
        <ion-item class="descanso" *ngIf="service.descansos[0] == (i+1)"><h2> Descanso </h2></ion-item>
        <ion-item class="descanso" *ngIf="service.descansos[1] == (i+1)"><h2> Descanso </h2></ion-item>
        <ion-item class="descanso" *ngIf="service.descansos[2] == (i+1)"><h2> Descanso </h2></ion-item>
        <ion-item class="descanso" *ngIf="service.descansos[3] == (i+1)"><h2> Descanso </h2></ion-item>
      </div>
    </div>
    <br/>
    <ion-row>
      <ion-col  *ngIf="service.hayfotos" no-padding col-12 class="slidezone c">
        <h4>Galería de fotos</h4>
        <div class="galeria">
          <ion-img item-start (click)="service.verImagengaleria(service.fotos, a+i)"  [src]="galeria" class="galeria_img" *ngFor="let galeria of service.fotos | slice:i:i+7; let a = index;"></ion-img>
        </div>
        <br/>
        <ion-buttons>
        <ion-button  fill="solid" [disabled]="i<=0" (click)="i=i-7;"><ion-icon class="arrow-back" name="chevron-back-outline"></ion-icon> Ant</ion-button>
        <ion-button  fill="solid" [disabled]="i>=service.fotos.length-10" (click)="i=i+7;">Sig <ion-icon class="arrow-forward" name="chevron-forward-outline"></ion-icon></ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row> 
    <br/>  
    <ion-row>
      <ion-col>
        <div *ngIf="service.hayfotos" no-padding col-12  class="slidezone n"><h4>Últimos vídeos</h4></div>  
        <swiper-container class="slidezone n" id="swiper" [loop]="false" [pagination]="{type:'bullets', clickable:true}" navigation="true">
          <swiper-slide (click)="service.openVideo(videos.node.link)" *ngFor="let videos of service.contenidos_portada">
            <ion-img class="img1" col-3 item-start [src]="videos.node.link| miniaturavideo "></ion-img>
            <ion-label class="etiqueta ion-text-center">{{videos.node.agrupacion}}</ion-label>
          </swiper-slide>
        </swiper-container>
      </ion-col>
    </ion-row>  
    <br/>
    <ion-row>
      <ion-col>
        <div no-padding col-12  class="slidezone n"><h4>Últimas noticias</h4></div>     
        <swiper-container class="slidezone n" id="swiper2" [loop]="false" [pagination]="{type:'bullets', clickable:true}" navigation="true">
          <swiper-slide  *ngFor="let item4 of service.noticia_item">
            <ion-card (click)="service.ir_a_noticia(item4.node)">
              <ion-img alt="{{item4.node.title}}" [src]="item4.node.mainimage"></ion-img>
              <ion-label class="text-end">
                <span class="fecha text-end">{{item4.node.nodedate}}</span>
                <h2>{{item4.node.title}}</h2>
              </ion-label>
            </ion-card>
          </swiper-slide>
        </swiper-container>
      </ion-col>
    </ion-row>
  </div>
  <br/>
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col size="6" size-md="4" *ngFor="let button of buttons">
          <ion-button expand="block" [routerLink]="button.url">{{ button.nombre }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

</ion-content>