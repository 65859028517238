import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
//import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';


@Component({
  selector: 'page-modalVideo',
  templateUrl: 'modalVideo.html',
  styleUrls: ['modalVideo.scss'],
})

export class ModalVideoPage implements OnInit{

  @Input() video:SafeResourceUrl;

  constructor(
    // private screen: ScreenOrientation, 
    public service:ServicioCarnavalService,
    private modalCtrl:ModalController, private domSanitizer:DomSanitizer,
    ){
    this.video="";
  }

  ngOnInit(): void {
    this.service.setearpantallaFB("Vídeo","VídeoScreen");
    let url = "https://www.youtube.com/embed/";
    this.video = this.domSanitizer.bypassSecurityTrustResourceUrl(url + this.video);
    //this.screen.lock(this.screen.ORIENTATIONS.LANDSCAPE);
  }

  onViewDidLoad() { }

  ngOnDestroy() { // this.screen.unlock();
    }

  cerrarModal(){ return this.modalCtrl.dismiss({'dismissed':true }); }

}
