import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-mas-carnaval',
  templateUrl: './mas-carnaval.component.html',
  styleUrls: ['./mas-carnaval.component.scss'],
})
export class MasCarnavalComponent  implements OnInit {

  filtro:any='';
  listafechas:any=[];
  constructor(public service: ServicioCarnavalService, private readonly titleService: Title) {
    const fecha = new Date();
    let agno = fecha.getFullYear();
    for (let i=agno; i>=2010; i--){
      this.listafechas.push(i);
    }
   }

  ngOnInit() {
    this.service.setearpantallaFB("MásCarnaval2","MásCarnaval2Screen");
    this.service.cargarVideospopurri("selected","");
    // this._ns.comprobarPubli("app-carnaval-coac-sesiones");
    // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
  }

  filterData (valor:any,tipo:any){
    // console.log(valor, tipo);
    this.service.seteareventoFB("filtro_mascarnaval_agno2",valor,"");
    this.service.cargarVideospopurri(valor,"");
  }

  busqueda(){
    this.service.seteareventoFB("filtro_mascarnaval_busqueda2","busqueda","");
  }
  
}
