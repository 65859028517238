import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcronica',
})
export class TcronicaPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let cadena:string ="";
    let nuevovalor:any[] = [];
    nuevovalor = value.split('h2>');
    if (nuevovalor.length > 1){
      cadena = nuevovalor[1].substring(0,nuevovalor[1].length-2);
    }
    else{
      cadena = "";
    }
    return cadena;
  }
}
