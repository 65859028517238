import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DirectosComponent } from './paginas/directos/directos.component';
import { SesionesComponent } from './paginas/sesiones/sesiones.component';
import { SesionesVideosComponent } from './paginas/sesiones-videos/sesiones-videos.component';
import { SesionesFotosComponent } from './paginas/sesiones-fotos/sesiones-fotos';
import { SesionesAudiosComponent } from './paginas/sesiones-audios/sesiones-audios';
import { AgrupacionesPage } from './paginas/agrupaciones/agrupaciones';
import { DetalleagrupacionComponent } from './paginas/detalleagrupacion/detalleagrupacion.component';
//import { VideoPlayerComponent } from './paginas/video-player/video-player.component';
import { ShakaPlayerComponent } from './paginas/shaka-player/shaka-player.component';
import { HomeCarnavalComponent } from './paginas/home-carnaval/home-carnaval.component';
import { CartelesComponent } from './paginas/carteles/carteles.component';
import { NoticiasComponent } from './paginas/noticias/noticias.component';
import { NoticiasDetalleComponent } from './paginas/noticias-detalle/noticias-detalle.component';
import { EventosComponent } from './paginas/eventos/eventos.component';
import { EventosDetalleComponent } from './paginas/eventos-detalle/eventos-detalle.component';
import { MasCarnavalComponent } from './paginas/mas-carnaval/mas-carnaval.component';
import { ProgramasTvComponent } from './paginas/programas-tv/programas-tv';
import { RankingComponent } from './paginas/ranking/ranking';
import { VotacionesComponent } from './paginas/votaciones/votaciones';
import { EnlacesComponent } from './paginas/enlaces/enlaces';

import { ProgramacionComponent } from './paginas/programacion/programacion';


const routes: Routes = [

  { path: '', component: HomeCarnavalComponent, title: 'Portada'},
  { path: 'directos', component: DirectosComponent, title: 'Directos'},
  { path: 'carnaval/sesiones', component: SesionesComponent, title: 'Sesiones'},
  { path: 'carnaval/sesiones-videos', component:SesionesVideosComponent, title: 'Vídeos Sesiones'},
  { path: 'carnaval/sesiones-fotos', component:SesionesFotosComponent, title: 'Fotos Sesiones'},
  { path: 'carnaval/sesiones-audios', component:SesionesAudiosComponent, title: 'Audios Sesiones'},
  { path: 'carnaval/agrupaciones', component: AgrupacionesPage, title: 'Agrupaciones'},
  { path: 'carnaval/agrupacion/:id_grupo', component: DetalleagrupacionComponent, title: 'Agrupación'},  
  { path: 'canal/:canal', component: ShakaPlayerComponent, title: 'Canal' },  
  { path: 'carnaval/carteles', component: CartelesComponent, title: 'Carteles'},
  { path: 'carnaval/noticias', component: NoticiasComponent, title: 'Noticias'},
  { path: 'carnaval/noticia/:nid', component: NoticiasDetalleComponent, title: 'Carnaval Noticia '},
  { path: 'carnaval/agenda', component: EventosComponent, title: 'Agenda'},
  { path: 'carnaval/agenda/:nid', component: EventosDetalleComponent, title: 'Agenda'},
  { path: 'carnaval/mas-carnaval', component: MasCarnavalComponent, title: 'Más Carnaval'},
  
  { path: 'carnaval/programas-tv', component: ProgramasTvComponent, title: 'Programas Tv'},
  { path: 'carnaval/ranking', component: RankingComponent, title: 'Ranking'},
  { path: 'carnaval/votaciones', component: VotacionesComponent, title: 'Votaciones'},
  { path: 'carnaval/enlaces', component: EnlacesComponent, title: 'Enlaces'},
  { path: 'carnaval/programacion', component: ProgramacionComponent, title: 'Programación'}, 
   
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}