import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pase',
})
export class PasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value != undefined){
      let value2 = value.toLowerCase();
      if (value2.indexOf("preliminar") > -1) {value="Preliminar";}
      if (value2.indexOf("cuartos") > -1) {value="Cuartos";}
      if (value2.indexOf("final") > -1) {value="Final";}
      if (value2.indexOf("semifinal") > -1) {value="Semifinal";}
    }
    return value;
  }
}
