    <ion-item>
      <ion-select slot="end" class="categoria-filtro"  interface="popover" (ionChange)="onChangecategoria(categoria2.value)" value="adulto" #categoria2>
        <div slot="label">Categoría:</div>
        <ion-select-option value="adulto" aria-label="Adulto">Adulto</ion-select-option>
        <ion-select-option value="juvenil" aria-label="Juvenil">Juvenil</ion-select-option>
        <ion-select-option value="infantil" aria-label="Infantil">Infantil</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-toolbar class="filters">
      <ion-grid>
        <ion-row class="filter">
          <ion-col  size="12">
            <!--swiper-container (swiperslidechange)="onSlideChange($event,categoria2.value)" #swipera  id="swipera"  [loop]="false" [centeredSlides]="true" navigation="true"-->
            <swiper-container #swipera  id="swipera"  [loop]="false" [centeredSlides]="true" navigation="true">
                <swiper-slide (click)="onChangefecha(fechas.agno+'-'+fechas.mes+'-'+fechas.dia, categoria2.value,i)" [ngClass]="{'selected':miraindice(i)}" *ngFor="let fechas of service.ajustes.filtrosa; let i=index;">
                <!--swiper-slide (click)="onSlideClick(i)" [ngClass]="{'selected':miraindice(i)}" *ngFor="let fechas of service.ajustes.filtrosa; let i=index;"--> 
                <p class='agno'>{{fechas | fechasfiltro:"0"}}</p>
                <p class='dia'>{{fechas | fechasfiltro:"1"}}</p>
              </swiper-slide>
            </swiper-container>
            <swiper-container  #swiperj id="swiperj"  class="ocultar" [loop]="false" [centeredSlides]="true" navigation="true">
              <swiper-slide (click)="onChangefecha(fechas.agno+'-'+fechas.mes+'-'+fechas.dia, categoria2.value,i)" [ngClass]="{'selected':miraindice(i)}" *ngFor="let fechas of service.ajustes.filtrosj; let i=index;">
                <p class='agno'>{{fechas | fechasfiltro:"0"}}</p>
                <p class='dia'>{{fechas | fechasfiltro:"1"}}</p>
              </swiper-slide>
            </swiper-container>
            <!--swiper-container *ngIf="categoria2.value==='infantil'" id="swiperi" (slidechangetransitionend)="slideChange()"  [loop]="false" [centeredSlides]="true"-->
            <swiper-container #swiperi id="swiperi"  class="ocultar" [loop]="false" [centeredSlides]="true">
              <swiper-slide (click)="onChangefecha(fechas.agno+'-'+fechas.mes+'-'+fechas.dia, categoria2.value,i)" [ngClass]="{'selected':miraindice(i)}" *ngFor="let fechas of service.ajustes.filtrosi; let i=index;">
                <p class='agno'>{{fechas | fechasfiltro:"0"}}</p>
                <p class='dia'>{{fechas | fechasfiltro:"1"}}</p>
              </swiper-slide>
            </swiper-container>
          </ion-col>
        </ion-row>
      </ion-grid>
      
    </ion-toolbar> 