import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-programacion',
  templateUrl: './programacion.html',
  styleUrls: ['./programacion.scss']
})

export class ProgramacionComponent implements OnInit, OnDestroy {

  pdfLink:any;

  constructor(private _sanitizer: DomSanitizer, private route: ActivatedRoute,
    public service: ServicioCarnavalService, private readonly titleService: Title) {

      
  }

  ngOnInit(): void {
    this.service.setearpantallaFB("Noticias-detalleScreen2","ProgramaciónScreen2");
    this.pdfLink = this._sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url='+this.service.ajustes.programa);

    //this.service.iraEnlace(this.service.ajustes.programa);
    //this.service.comprobarPubli("app-carnaval-programacion");
    // this.firebaseAnalytics.logEvent('page_view', {page: "enlaces-de-interes"});
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

}
