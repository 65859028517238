import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoContenidoCof'
})
export class TipoContenidoCofPipe implements PipeTransform {

  transform(value: Array<any>, args: string =''): Array<any> {
    console.log(value);
    if (!args){
      console.log("no recibo argumento");
      return value;
    }
    if (!value){
      return value;
    }
    return value.filter(({ node }) => node.contenido.toLowerCase() === args);
  }

}
