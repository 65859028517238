<ion-content class="modalGaleria">
  <div class="contenido">
    <ion-buttons slot="end">
      <ion-button (click)="cerrarModal()">
        <ion-icon  name="close-circle" size="large" float-end></ion-icon>
      </ion-button>
    </ion-buttons>
    <swiper-container  #swiper  [slidesPerView]="1" [loop]="false">
        <swiper-slide *ngFor="let imagen of galeria">
            <ion-img [src]="imagen"></ion-img>
        </swiper-slide>
    </swiper-container>
  </div>
</ion-content>