import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-sesiones-audios',
  templateUrl: './sesiones-audios.html',
  styleUrls: ['./sesiones-audios.scss'],
})
export class SesionesAudiosComponent  implements OnInit, OnDestroy {

  constructor(public service: ServicioCarnavalService, private readonly titleService: Title) { }

  ngOnInit() {
    this.service.setearpantallaFB("Audios-sesión2","Audios-sesiónScreen2");
    this.service.comprobarPubli("app-carnaval-coac-audios");
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

}
