import { Component,  OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

declare let shaka:any;

@Component({
  selector: 'app-shaka-player',
  templateUrl: './shaka-player.component.html',
  styleUrls: ['./shaka-player.component.css']
})

export class ShakaPlayerComponent implements OnInit, OnDestroy {
  //@ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;
  //@ViewChild('videoContainer') videoContainerRef: ElementRef | undefined;
  nid: any;
  directo: any;
  private sub: any;
  navbar:any;
  id:any;
  id2:any;
  statuscookie:string|null='aaa';

  //videoElement: HTMLVideoElement | undefined;
  //videoContainerElement: HTMLDivElement | undefined;
  player: any;
  //ui: any;

  //constructor(private navCtrl:NavController, private screen: ScreenOrientation,private route: ActivatedRoute, private service: ServicioCarnavalService, private readonly titleService: Title) { }
  constructor(private navCtrl:NavController, private route: ActivatedRoute, private service: ServicioCarnavalService, private readonly titleService: Title) { }

  
  title = 'videojs-demo';
  videoSrc:string = 'https://ondacadiztv.es:30443/octv/directo_multi/playlist.m3u8';

  ngOnInit() {
    this.service.setearpantallaFB("Reproductor2","ReproductorScreen2");
    this.directo = this.service.capitulo_item;
    //this.screen.lock(this.screen.ORIENTATIONS.LANDSCAPE);
    if (this.directo){
      const title = 'Directo ' + this.directo?.title; // encodeURI(this.service.sinAcentosNiEspacios (this.capitulo?.programa.toLowerCase()));
      this.titleService.setTitle(title);    
      this.videoSrc = this.directo.link;

    }else{
      this.sub = this.route.params.subscribe(params => {
        this.nid = params['canal']; // (+) converts string 'id' to a number
      });
      this.service.cargarDirectos(this.nid, 'all')
      .subscribe(resp3 => {
        console.log(resp3)
        this.directo = resp3.nodes[0];     
      //}else if (item.linknimble){
      //item.stream = 'nimble';
      //item.link = item.linknimble;
        const title = 'Directo2 ' + this.directo?.title;
        this.titleService.setTitle(title);
      });
    }
    this.service.comprobarPubli("app-carnaval-tv-oc");

  }

  ngOnDestroy() {
//  this.screen.unlock();
  }

  public initPlayer(){
    //this.player = new shaka.Player(this.videoElement);
    //this.player.configure({
    //  streaming: { lowLatencyMode: true, inaccurateManifestTolerance: 100,    rebufferingGoal: 0.01  }
    //});
    //console.log("directo......:"+this.directo.link);
    //this.player.load (this.directo.link)
    //  .then(()=>{ this.videoElement?.play();  })
    //  .catch((e: any) => { console.error(e);  });
      //  .load('https://www.bok.net/dash/tears_of_steel/cleartext/stream.mpd')
      //  .load('https://wowza.dantia.net/live/camara207.stream/playlist.m3u8')
      //  .load('https://adc-hls.flumotion.com/adc/live_source/chunks.m3u8')
      //  .load('rtmp://ondacadiztv.es/cam/elcano')
      //  .load('wss://ondacadiztv.es:30443/octv/directo')
      //  .load('https://ondacadiztv.es:30443/octv/directo/manifest.mpd')
      //  .load('wss://ondacadiztv.es:30443/octv/directo_multi')
      //  .load('https://ondacadiztv.es:30443/cam/elcano/manifest.mpd')
      //  .load('https://ondacadiztv.es:30443/octv/24h/manifest.mpd')
      //  .load('https://ondacadiztv.es:30443/octv/directo_multi/manifest.mpd')
   // this.ui = new shaka.ui.Overlay(this.player, this.videoContainerElement, this.videoElement );
   // const config = {'overflowMenuButtons' : ['quality']};
   // this.ui.configure(config);  
  }

  cerrarModal(){
    this.navCtrl.back();
  }

}
