import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss'],
})
export class NoticiasComponent  implements OnInit, OnDestroy {

  noticias:any=[];
  filtro:any='';

  constructor(public service: ServicioCarnavalService, private meta:Meta) { }

  ngOnInit() {
    this.meta.addTag({ property: "og:title", content: "this.service.noticia_detalle_item.title" });
    this.meta.addTag({ property: "og:description", content :"El contenido del Carnaval de Cádiz"});
    this.meta.addTag({ property: "og:image", content:"this.service.noticia_detalle_item.mainimage"});
  
    this.service.setearpantallaFB("Noticias","NoticiasScreen");
    this.service.cargarNoticiasCarnaval("all","all");
    this.service.comprobarPubli("app-carnaval-actualidad-noticias");

  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

}
