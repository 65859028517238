import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recortaParrafo'
})
export class RecortaParrafoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    //  $data="<p> un texto del párrafo</p>";
    // $texto=trim(strip_tags($data)); // un texto del párrafo
    let palabras = value.split("</p>",2);
    return palabras;
  }

}
