import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {
  transform(value:string, ...args: unknown[]): string {
    const meses = ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov','Dic'];
    let fecha_total:string[] = value.split('T');
    let fecha:string[] = fecha_total[0].split('-');
    let dia = fecha[0];
    let mes = meses[+fecha[1]];
    let year = fecha[2];
    if (fecha_total.length>1){
      return year+' '+mes+' '+dia;
    }else{
      return dia+' '+mes+' '+year;
    }
    
  }

}
