import { Component, Input, OnInit, ViewChild,ElementRef} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
import { Subscription, interval } from 'rxjs';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-modal-publi',
  templateUrl: './modal-publi.html',
  styleUrls: ['./modal-publi.scss'],
})
export class ModalPubliComponent  implements OnInit {
  @Input() publi:any;
  observableCierrepublicidad: Subscription;

  constructor( private modalCtrl:ModalController, private platform:Platform, public service:ServicioCarnavalService
  //private firebaseAnalytics:FirebaseAnalytics
  ){
    //console.log(service.ajustes.publicidades[0] );
    //this.observableCierrepublicidad = interval(parseInt(this.publicidad[0].node.tiempo_activa)*1000).subscribe(()=>{
    this.observableCierrepublicidad = interval(5000).subscribe(()=>{
      this.observableCierrepublicidad.unsubscribe();
      return this.modalCtrl.dismiss({'dismissed':false });
    });
  }

ngOnInit(): void {
  this.service.setearpantallaFB("Publicidad","PublicidadScreen");
}

cerrarModal(op:number){
  if (op){
    this.observableCierrepublicidad.unsubscribe();
    return this.modalCtrl.dismiss({'dismissed':false });
  }else{
    this.observableCierrepublicidad.unsubscribe();
    return this.modalCtrl.dismiss({'dismissed':true });
  }
}

establet(){
  if (this.platform.is('ipad') || this.platform.is('tablet')){
    return true;
  }else{
    return false;
  }
}

}
