import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NavController, ModalController, Platform } from '@ionic/angular';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-eventos-detalle',
  templateUrl: './eventos-detalle.component.html',
  styleUrls: ['./eventos-detalle.component.scss'],
})
export class EventosDetalleComponent  implements OnInit, OnDestroy {

  noticia:any;
  private sub:any;
  ruta:any;
  nid:any;

  constructor(
    public navCtrl: NavController,
    private router:Router, 
    private route: ActivatedRoute,
    public service: ServicioCarnavalService) { 
    //console.log("en detalle");
    //console.log(this.service.noticia_item);
    this.sub = this.route.params.subscribe(params => {
      this.nid = params['nid']; // (+) converts string 'id' to a number
      this.service.cargarEventos(this.nid);
      //  console.log(this.service.noticia_item);
      });
  }

  ngOnInit() {
    this.service.setearpantallaFB("Evento-detalle","Evento-detalleScreen");
    this.service.comprobarPubli("app-carnaval-coac-agenda");
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  regresar(){
    this.navCtrl.back();
  }

  llevame(lat:string,lon:string){
    //let ruta="https://www.google.es/maps/dir/36.5078717,-6.2712239/36.527057,-6.290679/@36.527128,-6.3256297,13z/data=!4m2!4m1!3e2"
    let ruta="https://www.google.es/maps/dir//"+lat+","+lon+"/@36.527128,-6.3256297,13z/data=!4m2!4m1!3e2";
    //this.iab.create(ruta,"_system");
  }

}
