<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Sesiones</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">

    <app-filtro-sesiones></app-filtro-sesiones>  
    <ion-list>
      <div *ngFor="let sesion of service.sesion_agrupacion; let i = index">
        
          <ion-item (click)="irDetalleAgrupacion('all',service.id_grupos[i]);">
            <ion-label>{{service.hora1[i]}}  {{sesion | sesion:2 }}
            <h2>{{sesion | sesion:1 }}</h2></ion-label>
            <!--img item-end class="icono"  src="/assets/imgs/icon_mas.png"-->
            <ion-icon slot="end" class="arrow-forward" name="add-outline"></ion-icon>

          </ion-item>
          <ion-item class="descanso" *ngIf="service.descansos[0] == (i+1)"><h2> Descanso </h2></ion-item>
          <ion-item class="descanso" *ngIf="service.descansos[1] == (i+1)"><h2> Descanso </h2></ion-item>
          <ion-item class="descanso" *ngIf="service.descansos[2] == (i+1)"><h2> Descanso </h2></ion-item>
          <ion-item class="descanso" *ngIf="service.descansos[3] == (i+1)"><h2> Descanso </h2></ion-item>
      </div>
    </ion-list>
  </div>
</ion-content>