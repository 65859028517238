import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'miniaturavideo',
})
export class MiniaturavideoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let cadena = `https://img.youtube.com/vi/${value.slice(-11)}/hqdefault.jpg`;
    return cadena;
  }
}
