import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.html',
  styleUrls: ['./ranking.scss'],
})
export class RankingComponent  implements OnInit, OnDestroy {
  
  rango:any;
  tipoagrupacion:any;
  
  constructor(public service: ServicioCarnavalService, private readonly titleService: Title) {
   }

  ngOnInit() {
    this.service.setearpantallaFB("Ranking2","RankingScreen2");
    this.service.cargarRanking("all");
    this.service.comprobarPubli("app-carnaval-participa-ranking");
    // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  filterData (valor:any){
    // console.log(valor, tipo);
    this.service.cargarRanking(valor);
  }
  
}
