import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioCarnavalService } from '../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Swiper } from 'swiper';

@Component({
  selector: 'app-home-carnaval',
  templateUrl: './home-carnaval.component.html',
  styleUrls: ['./home-carnaval.component.scss'],
})

export class HomeCarnavalComponent implements OnInit, OnDestroy {
  i=0;
  buttons = [
    {'nombre':'Onda Cádiz TV','url':'/directos'},
    {'nombre':'Vídeos COAC','url':'/carnaval/sesiones-videos'},
    {'nombre':'Audios COAC','url':'/carnaval/sesiones-audios'},
    {'nombre':'Galería de fotos','url':'/carnaval/sesiones-fotos'},
    {'nombre':'Agrupaciones','url':'/carnaval/agrupaciones'},
    {'nombre':'Noticias','url':'/carnaval/noticias'},
    {'nombre':'Agenda','url':'/carnaval/agenda'},
    {'nombre':'Pregones','url':'/carnaval/mas-carnaval'},
    {'nombre':'Carteles','url':'/carnaval/carteles'},
    {'nombre':'Programas TV','url':'/carnaval/programas-tv'}
  ];

  constructor(private renderer: Renderer2, public service:ServicioCarnavalService, private route: ActivatedRoute, private router:Router) {
    this.renderer.addClass(document.body, 'body-class-cofrade');
    this.service.cargarAjustes ();
  }

  ngOnInit() {
    var index = 0;
    this.service.setearpantallaFB("Home2","Home2Screen");
    this.service.cargarContenidosportada();
    this.service.cargarNoticiasCarnaval();
    this.service.comprobarPubli("app-carnaval-inicio");
  }

  ngOnDestroy(): void {
   // this.service.observablePublicidad.unsubscribe();
   // this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  irDetalleAgrupacion(nombre:any,agrupacion:any){
    this.router.navigate(['/carnaval/agrupacion/'+agrupacion]);
  }

}
