<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Vídeos por sesión</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <app-filtro-sesiones></app-filtro-sesiones>
    <ion-grid>
      <ion-row class="carta">
        <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12" (click)="service.openVideo(sesion.node.link)" *ngFor="let sesion of service.videoscoac[0]">
          <ion-row class="item">
            <ion-col size-md="4" size-sm="4" size-xs="3"><ion-img [src]="sesion.node.foto_medio"></ion-img></ion-col>
            <ion-col size-md="8" size-sm="8" size-xs="9">{{sesion.node.titulo}}</ion-col>
          </ion-row>
        </ion-col>
      </ion-row>  
    </ion-grid>
  </div>
</ion-content>