import { Component, Input, OnInit, ViewChild,ElementRef} from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Component({
  selector: 'app-modal-imagen',
  templateUrl: './modal-imagen.html',
  styleUrls: ['./modal-imagen.scss'],
})
export class ModalImagenComponent  implements OnInit {
  @ViewChild('swiper')  swiperRef: ElementRef | undefined; 
  swiperModules = [IonicSlides];

  @Input() galeria:any[];
  @Input() index:any;

constructor(
  private modalCtrl:ModalController
  //private firebaseAnalytics:FirebaseAnalytics
  ){
  this.galeria=[];
}

ngOnInit(): void {
  //FirebaseAnalytics.setScreenName({
  //  screenName: "GaleríaCarnaval",
  //  nameOverride: "GaleríaScreen",
  //})

}

ngAfterViewInit(){
  //this.swiperRef?.nativeElement.swiper.slideNext();
  this.swiperRef?.nativeElement.swiper.slideTo(this.index, 1500);
}

onViewDidLoad() {
 // this.firebaseAnalytics.logEvent('page_view', {page: "modalvideo"});
}

cerrarModal(){
    return this.modalCtrl.dismiss({'dismissed':true });
}

}
