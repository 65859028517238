<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Audios por sesión</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <!--
      <iframe src="https://www.ivoox.com/player_es_podcast_2355963_zp_1.html?c1=5c1732" width="90%" height="800" frameborder="0" allowfullscreen="" scrolling="no" loading="lazy"></iframe>
    -->

      <app-filtro-sesiones></app-filtro-sesiones>
      <ion-grid>
        <ion-row>
        <ion-col  size-md="4" size-sm="6" size-xs="12" *ngFor="let sesion of service.videoscoac[0]">
          <ion-label class="etiqueta ion-text-center">
            <h3 >{{sesion.node.titulo}}</h3>
            <p><audio controls src={{sesion.node.audio|secure}}>No se puede reproducir este audio</audio></p>
          </ion-label>
        </ion-col>
      </ion-row>
      </ion-grid>  

  </div>

</ion-content>