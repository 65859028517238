import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { ServiciopushService } from './serviciopush.service';
import { Platform } from '@ionic/angular';
import { App } from '@capacitor/app';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isRemainder:boolean=false;

  public appPages = [
    { title: 'Directos', url: '/directos', icon: 'play-circle' },
    { title: 'Concurso del Falla', url: '#', icon: 'star',
      subPages: [
        { title: 'Hoy en el Falla', url: '/carnaval/sesiones', icon: 'calendar' },
        { title: 'Vídeos del COAC', url: '/carnaval/sesiones-videos', icon: 'film' },
        { title: 'Audios del COAC', url: '/carnaval/sesiones-audios', icon: 'musical-notes' },
        { title: 'Galerías COAC', url: '/carnaval/sesiones-fotos', icon: 'images' }
      ]
    }, 
    { title: 'Agrupaciones', url: '/carnaval/agrupaciones', icon: 'people' },
    { title: 'Actualidad', url: '#', icon: 'earth',
      subPages: [
        { title: 'Noticias', url: '/carnaval/noticias', icon: 'flame' },
        { title: 'Agenda', url: '/carnaval/agenda', icon: 'time' },
        { title: 'Programación', url: '/carnaval/programacion',icon: 'calendar'}
      ]
    },
    { title: 'Más Carnaval', url: '#', icon: 'add-circle',
      subPages: [
        { title: 'Pregones y más', url: '/carnaval/mas-carnaval', icon: 'megaphone' },
        { title: 'Programas de TV', url: 'carnaval/programas-tv', icon: 'tv' },
        { title: 'Carteles', url: '/carnaval/carteles',icon: 'images'}
      ]
    },
    { title: 'Participa', url: '#', icon: 'apps',
      subPages: [
        { title: 'Votaciones', url: '/carnaval/votaciones', icon: 'star' },
        { title: 'Ranking', url: '/carnaval/ranking', icon: 'tv' }
        //,
        //{ title: 'Favoritos', url: '/carnaval/mas-carnaval', icon: 'megaphone' },
        //{ title: 'Chat', url: '/carnaval/agenda', icon: 'tv' }        
      ]
    },
    { title: 'De interés', url: '#', icon: 'alert',
      subPages: [
        { title: 'Enlaces', url: '/carnaval/enlaces', icon: 'link' },      
      ]
    },
   // { title: 'Salir', url: '/', icon: 'close' },
  ];

  constructor(private platform:Platform, private fcm:ServiciopushService) {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')){ 
        console.log("initPush");
        this.fcm.initPush();
      }else{
        console.log("initSW");
        this.fcm.initSW();
      }
    }).catch(e => {
      console.log(e);
    });
  }

  salir(){
      App.exitApp();
  }
}
