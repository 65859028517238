<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Fotos por sesión</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">

    <app-filtro-sesiones></app-filtro-sesiones>
  
    <div *ngFor="let sesion of service.videoscoac[0]; let i = index;">
      <ion-list>
        <h3>{{sesion.node.titulo}}</h3>
        <div class="galeria">
          <img item-start (click)="service.verImagengaleria(this.service.fotos_original[i], a)"  [src]="galeria" class="galeria_img" *ngFor="let galeria of this.service.fotos_medio[i]; let a = index;">
        </div>
      </ion-list>
    </div>
    
  </div>
</ion-content>