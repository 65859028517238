import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the SesionPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'sesion',
})
export class SesionPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, arg1:number) {
    var fecha2;
    if (value != undefined){
      let valor = value.split('::');
      //console.log (valor[arg1]);
      //if (fecha != undefined){
      //  fecha2 = fecha.substring(6,10)+"-"+fecha.substring(3,5)+"-"+fecha.substring(0,2)+"T"+valor[arg1]+":00";
      //}else{
        fecha2 = valor[arg1];
      //}
      return fecha2;
      }
      else {
        return "**";
      }
  }
}
