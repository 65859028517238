<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Agenda</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <ion-grid>
      <ion-row class="carta">
        <ion-col size-lg="4" size-md="6" size-sm="12" size-xs="12" *ngFor="let item4 of (service.eventos_item )">
          <ion-card (click)="service.ir_a_evento(item4.node)">
            <h3>{{ item4.node.title}}</h3>
            <p class="etiqueta">{{ item4.node.fechadefinitiva }} {{item4.node.horadefinitiva }}</p>
                <ion-img alt="{{item4.node.title}}" [src]="item4.node.thumbimage"></ion-img>
                <p [innerHTML]="item4.node.descripcion">{{item4.node.description}}</p>
                <p *ngIf="item4.node.price"><ion-label>Precio: </ion-label>{{ item4.node.price}}</p>
                <p *ngIf="item4.node.location"><ion-label>Dirección: </ion-label>{{ item4.node.location }}</p>
                <p *ngIf="item4.node.address"><ion-label>Dirección: </ion-label>{{ item4.node.address }}</p>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>