<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Votaciones</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <ion-grid>
      <div *ngIf="service.itemsparavotos.length == 0">
        <h3>No existen votaciones disponibles</h3>
      </div>
      <div *ngFor="let itemsparavotos2 of service.itemsparavotos">
        <ion-row *ngFor="let itemsparavotos of itemsparavotos2.nodes"
              (click)="service.votarItem(itemsparavotos.node)">
          <ion-col size-md="2" size-sm="2" size-xs="2">
            <img src="assets/icon/{{itemsparavotos.node.grouptype|lowercase}}.png">
          </ion-col>
          <ion-col size-md="9" size-sm="9" size-xs="9">
            <p color="secundary" bold text-left>{{itemsparavotos.node.grouptype}}<br/>
            {{itemsparavotos.node.title}}</p>
          </ion-col>
          <ion-col size-md="1" size-sm="1" size-xs="1">
            <!--p>{{itemsparavotos.node.author}}</p-->
            <img item-end class="icono" src="/assets/img/icon_nav_adelante.png">  
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </div>
</ion-content>