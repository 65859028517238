<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Directos</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <ion-grid>
      <ion-row>
        <ion-col size-lg="6" size-md="6" size-sm="6" size-xs="12" *ngFor="let p of directos"> 
          <ion-card  (click)="service.ir_a(p)">
            <img alt="{{p.title}}" src="{{p.imagen}}" />
            <ion-card-header>
              <ion-card-title>{{p.title}}</ion-card-title>
              <!--ion-card-subtitle>Card Subtitle</ion-card-subtitle-->
            </ion-card-header>
          
            <!--ion-card-content>
              Here's a small text description for the card content. Nothing more, nothing less.
            </ion-card-content-->
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>
</ion-content>
