import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-sesiones',
  templateUrl: './sesiones.component.html',
  styleUrls: ['./sesiones.component.scss'],
})
export class SesionesComponent  implements OnInit, OnDestroy {

  constructor(private _sanitizer: DomSanitizer, private route: ActivatedRoute, private router:Router,
    public service: ServicioCarnavalService, private readonly titleService: Title) { }

  ngOnInit() {
    this.service.setearpantallaFB("Sesiones2","SesionesScreen2");
      //this.service.cargarFiltrosSesiones();
      var index = this.service.buscarindice('adulto');
      //var index = 0;
      this.service.cargarSesiones("adulto",this.service.filtrosa[index].agno+'-'+this.service.filtrosa[index].mes+'-'+this.service.filtrosa[index].dia);
      //.subscribe(resp3 => { });
      this.service.comprobarPubli("app-carnaval-coac-sesiones");
      // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  irDetalleAgrupacion(nombre:any,agrupacion:any){
    this.router.navigate(['/carnaval/agrupacion/'+agrupacion]);
  }

}
