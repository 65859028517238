<ion-content class="modalVideo">

      <ion-toolbar>
        <ion-title>Vídeo</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="cerrarModal()">
            <ion-icon  name="close-circle" size="large" float-end></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div class="video-container">
          <iframe width="100%" height="100%"  [src]="video" frameborder="0" allowfullscreen autoplay></iframe>
      </div>
      <!--button (click)="cerrarModal()" ion-button block color="primary" >Cerrar </button-->


</ion-content>
