import { Injectable, Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { iprogramas, icapitulos, itematicas, icarnaval, idirectos, icofrade, icofradias } from './models/iprograma';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, interval, Subscription} from 'rxjs';
import { ModalController, Platform } from '@ionic/angular';
import { MessageService } from './message.service';
/*
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx';
*/
import { Device } from '@capacitor/device';
import { ModalMensajeComponent } from './paginas/modal-mensaje/modal-mensaje';
import { ModalVideoPage } from './paginas/modalVideo/modalVideo';
import { ModalImagenComponent } from './paginas/modal-imagen/modal-imagen';
import { ModalVotacionesComponent } from './paginas/modal-votaciones/modal-votaciones';
import { Browser } from '@capacitor/browser';
import { ModalPubliComponent } from './paginas/modal-publi/modal-publi';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class ServicioCarnavalService {

  debug = false;

  ajustes = {
    mostrar_tutorial: true,
    programa:"",
    fechacarnaval:"",
    sonando:0,
    nodes:[],
    filtrosa:<any>[],
    filtrosj:<any>[],
    filtrosi:<any>[],
    filtros:<any>[],
    publicidades:<any>[],
    filtro_agrupacion:{ fecha:"", modalidad:"", categoria:"", title:"" },
    votos:{fecha:0, uno:false , dos:false, tres:false, cuatro:false, cinco:false}
  }

  cargada = false;
  programa_item:any;
  capitulo_item:any;
  cofradia_item:any;
  noticia_item:any;
  noticia_detalle_item:any;
  eventos_item:any;
  eventos_detalle_item:any;
  host:string="https://ondacadiz.es";
  host_carnaval:string="https://contenidos.ondacadiz.es"; //carnaval.ondacadiz.es
  host_cofrade:string="https://contenidos.ondacadiz.es";  //cofrade.ondacadiz.es
  sesiones:any[] = [];
  filtros:any[] = [];
  filtrosa:any[] = [];
  filtrosj:any[] = [];
  filtrosi:any[] = [];
  filtrosr:any[] = [];
  filtrosagrupaciones:any[] = [];

  agrupaciones:any[] = [];
  romanceros:any[] = [];

  coros:any[] = [];
  comparsas:any[] = [];
  chirigotas:any[] = [];
  cuartetos:any[] = [];

  coros_a:any[] = [];
  comparsas_a:any[] = [];
  chirigotas_a:any[] = [];
  cuartetos_a:any[] = [];

  coros_j:any[] = [];
  comparsas_j:any[] = [];
  chirigotas_j:any[] = [];
  cuartetos_j:any[] = [];

  coros_i:any[] = [];
  comparsas_i:any[] = [];
  chirigotas_i:any[] = [];
  cuartetos_i:any[] = [];

  hora1:any[] = [];
  id_grupos:any[] = [];  
  descansos:any[] = [];
  sesion_agrupacion:any[] = [];

  detalleagrupacion:any[] = [];
  detalleagrupacion_contenidos:any[] = [];
  haycronica:boolean = false;
  hayaudio:boolean = false;
  hayfotos:boolean = false;
  hayvideos:boolean = false;
  boton:boolean = false;
  fotos:string[]=[];
  fotos_original:string[]=[];
  fotos_medio:string[]=[];

  contenidos_portada:any[] = [];

  videoscoac:any[] = [];
  videospopurri:any[] = [];
  programastv:any[] = [];

  itemsparavotos:any[] = [];
  resultado:any[] = [];
  ranking:any[] = [];
  rankingcuartetos:any =[];
  rankingchirigotas:any =[];
  rankingcoros:any =[];
  rankingcomparsas:any =[];

  disableButton:any = true;
  deviceId: any;
  carteles:any=[];

  enlaces:any[] = [];

  publicidades:any[] =[];
  observablePublicidad: Subscription = new Subscription;

  swiperParams1 = {
    slidesPerView: 1,
    breakpoints:{
      480: {slidesPerView: 2,spaceBetween: 5},
      640: {slidesPerView: 3,spaceBetween: 10},
      947: {slidesPerView: 4,spaceBetween: 15},
      1040: {slidesPerView: 5,spaceBetween: 20},
    },
    on:{ init(){}, },
  };

  swiperParams2 = {
    slidesPerView: 1,
    breakpoints:{
      480: {slidesPerView: 2,spaceBetween: 5},
      640: {slidesPerView: 2,spaceBetween: 5},
      947: {slidesPerView: 4,spaceBetween: 15},
      1040: {slidesPerView: 4,spaceBetween: 15},
    },
    on:{ init(){}, },
  };
  
  constructor( private http:HttpClient, private router: Router, 
    public messageService: MessageService,
    //private youtube: YoutubeVideoPlayer,
    private modalCtrl:ModalController,
    private platform:Platform,
  private meta:Meta){
     this.initFB();
 
    }

  async initFB(){
    if (( await Device.getInfo()).platform == 'web'){
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
      console.log("inicializado");
    }else{
      console.log( (await Device.getInfo()).platform);
    }
  }
  
  async setearpantallaFB(pantalla:string, nombre:string){
    FirebaseAnalytics.setScreenName({
      screenName: pantalla,
      nameOverride: nombre,
    })
  }

  async seteareventoFB(nombre:string, valor2:string, cat:string){
    FirebaseAnalytics.logEvent({
      name: nombre,
      params: {
        fecha: valor2,
        categoria: cat,
      },
    });
  } 

  ponerMetaetiquetas(titulo:string,descripcion:string,imagen:string){
    this.meta.updateTag({ property: "og:title", content: titulo });
    this.meta.updateTag({ property: "og:description", content: descripcion });
    this.meta.updateTag({ property: "og:image", content: imagen });

    this.meta.updateTag({ property: "twitter:card", content:"summary_large_image"});
    this.meta.updateTag({ property: "twitter:title", content: titulo });
    this.meta.updateTag({ property: "twitter:description", content: descripcion });
    this.meta.updateTag({ property: "twitter:image", content: imagen });
   
  }


  compartir() {
    if (navigator.share) {
      navigator.share({
        title: 'Disfruta del Carnaval de Cádiz',
        text: 'Compartido desde Carnaval.ondacadiz.es !!',
        url: this.router.url,
      })
      .then(() => {
        console.log('Compartido exitosamente');
      })
      .catch((error) => {
        console.error('Error al compartir', error);
      });
    } else {
      console.log('API de compartir no soportada en este navegador');
    }
  }

  cargarDirectos(nid:any, fijo:any){
    const url_directos = this.host + '/app_liveoc_directos/' + nid + "/" + fijo;
    this.log ("url_directos: " + url_directos);
    this.ponerMetaetiquetas('Emisiones en directo de OndaCádiz RTV',
       'Todas nuestras emisiones de televisión, radio, streaming y noticias 24h en un mismo lugar.',
       'https://ondacadiz.es/sites/default/files/logo_onda_cadiz_web_120_100_1.png'
      );
    return this.http.get<idirectos>( url_directos );
  }

  cargarFiltrosSesiones(){
    this.sesiones =[];
    this.filtrosa =[];
    this.filtrosj =[];
    this.filtrosi =[];
    const url_filtrossesiones = this.host_carnaval + "/app_carnaval_filtros_sesiones"; //no da ningún pero si los filtros
    this.log ("ns-filtrossesiones: " + url_filtrossesiones );   
    this.http.get<any>( url_filtrossesiones ).pipe(
        map( (data:any) =>  {
          //console.log(data.nodes[0].selected);
          let selected = data.nodes[0].selected;
          this.filtrosa.push(...data.nodes[0].dates[selected].Adulto);
          this.filtrosj.push(...data.nodes[0].dates[selected].Juvenil);
          this.filtrosi.push(...data.nodes[0].dates[selected].Infantil);
          this.filtrosa.forEach((item,key)=>{
                this.filtrosa[key]["agno"] = selected;
                this.filtrosa[key]["categoria"] = "adultos";
          });
          this.filtrosj.forEach((item,key)=>{
                this.filtrosj[key]["agno"] = selected;
                this.filtrosj[key]["categoria"] = "juvenil";
          });
          this.filtrosi.forEach((item,key)=>{
                this.filtrosi[key]["agno"] = selected;
                this.filtrosi[key]["categoria"] = "infantil";
          });
          //this.filtros = this.filtrosa;
          this.ajustes.filtrosa = this.filtrosa;
          this.ajustes.filtrosj = this.filtrosj;
          this.ajustes.filtrosi = this.filtrosi;
          //this.ajustes.filtros = this.filtros;
          //this.ajustes.publicidades.push(this.publicidades[0].nodes);
          this.guardar_storage();
          //console.log ("pidiendo para portada");
          //console.log(this.ajustes.filtrosa[0].agno+'-'+this.ajustes.filtrosa[0].mes+'-'+this.ajustes.filtrosa[0].dia);
          //this.cargarSesiones("adulto",this.ajustes.filtrosa[0].agno+'-'+this.ajustes.filtrosa[0].mes+'-'+this.ajustes.filtrosa[0].dia).subscribe();
          const date = new Date();
          this.cargarSesiones("adulto",date.toISOString().split('T')[0]); //.subscribe();

        }),
        catchError(this.handleError('fallo sesiones', []))    
    ).subscribe();
  }

  cargarPublicidades (){
    if (this.debug) {console.log ("cargarPublicidades"); }
    this.publicidades = [];
    let url = this.host_carnaval + "/app_carnaval_publicidad";

    this.http.get<any> ( url ).pipe(
      map( data => {
          this.publicidades.push(data);
          this.ajustes.publicidades = [];
          this.ajustes.publicidades.push(this.publicidades[0].nodes);
          this.guardar_storage();
        }),
        catchError(this.handleError('carga de publis ha fallado', []))  
      ).subscribe();
  }

  cargarOpciones (){
    if (this.debug) {console.log ("cargarOpciones"); }
    let url = this.host_carnaval + "/app_carnaval_opciones_20";

    this.http.get<any> ( url ).pipe(
      map( data => {
        if (this.debug) {console.log(data.nodes[0].node.programaoficial);}
          //this.publicidades.push(data);
          this.ajustes.programa = data.nodes[0].node.programaoficial;
          this.ajustes.fechacarnaval = data.nodes[0].node.fechacarnaval;
          this.guardar_storage();
        }),
        catchError(this.handleError('carga opciones ha fallado', []))  
      ).subscribe();
  }
  
  cargarAjustes(){
    if (this.debug) {console.log ("cargarAjustes"); }
    this.cargar_storage();
    if (this.ajustes.votos == undefined){
      this.ajustes.votos = {fecha:0, uno:false , dos:false, tres:false, cuatro:false, cinco:false};
      this.guardar_storage();
    }

    this.cargarFiltrosSesiones();
    this.cargarPublicidades();
    this.cargarOpciones();
  }

  buscarindice(categoria:any){
    let fecha=new Date();
    var agno = fecha.getFullYear();
    var mes = fecha.getMonth()+1;
    var dia = fecha.getDate();
    var i;
    var indexa = 0;
    if (categoria=='adulto'){ 
      var num_dias = this.ajustes.filtrosa.length;
      for (i=0; i < num_dias; i++ ){
        if (this.ajustes.filtrosa[i].agno==agno && parseInt(this.ajustes.filtrosa[i].mes) == mes && this.ajustes.filtrosa[i].dia == dia){
          indexa = i;
        }
      }
      num_dias = num_dias -1;
      //console.log (this.ajustes);
      if (
        (agno > this.ajustes.filtrosa[num_dias].agno) ||
        (agno == this.ajustes.filtrosa[num_dias].agno && mes > parseInt(this.ajustes.filtrosa[num_dias].mes)) ||
        (agno == this.ajustes.filtrosa[num_dias].agno && mes == parseInt(this.ajustes.filtrosa[num_dias].mes) && dia > this.ajustes.filtrosa[num_dias].dia)
      ){
        indexa = num_dias;
      }
    }else if (categoria=='infantil') { 
      var num_dias = this.ajustes.filtrosi.length;
      for (i=0; i < num_dias; i++ ){
        if (this.ajustes.filtrosi[i].agno==agno && parseInt(this.ajustes.filtrosi[i].mes) == mes && this.ajustes.filtrosi[i].dia == dia){
          indexa = i;
        }
      }
      num_dias = num_dias -1;
      if (
        (agno > this.ajustes.filtrosi[num_dias].agno) ||
        (agno == this.ajustes.filtrosi[num_dias].agno && mes > parseInt(this.ajustes.filtrosi[num_dias].mes)) ||
        (agno == this.ajustes.filtrosi[num_dias].agno && mes == parseInt(this.ajustes.filtrosi[num_dias].mes) && dia > this.ajustes.filtrosi[num_dias].dia)
      ){
        indexa = num_dias;
      }
    }else if (categoria=='juvenil') { 
      var num_dias = this.ajustes.filtrosj.length;
      for (i=0; i < num_dias; i++ ){
        if (this.ajustes.filtrosj[i].agno==agno && parseInt(this.ajustes.filtrosj[i].mes) == mes && this.ajustes.filtrosj[i].dia == dia){
          indexa = i;
        }
      }
      num_dias = num_dias -1;
      if (
        (agno > this.ajustes.filtrosj[num_dias].agno) ||
        (agno == this.ajustes.filtrosj[num_dias].agno && mes > parseInt(this.ajustes.filtrosj[num_dias].mes)) ||
        (agno == this.ajustes.filtrosj[num_dias].agno && mes == parseInt(this.ajustes.filtrosj[num_dias].mes) && dia > this.ajustes.filtrosj[num_dias].dia)
      ){
        indexa = num_dias;
      }
    }
    return indexa;
  }

  cargarSesiones(categoria:string,dia:string){
    if (this.debug) {console.log ("cargarSesiones"); }
    this.sesiones =[];
    this.hora1=[];
    this.id_grupos=[];
    this.descansos=[];
    this.sesion_agrupacion=[];
    //let url = URL_SERVICIOS + "/app_carnaval_coac_falla/adulto/2018-01-09";
    const url = this.host_carnaval + "/app_carnaval_sesiones/"+categoria+"/"+dia;
    //this.log( "pidiendo sesiones del dia: " + url);
    return this.http.get<any>( url ).pipe(
        map( (data:any) => {
          this.log(data.nodes);
          this.sesiones.push(...data.nodes);
          if (typeof this.sesiones[0] !== 'undefined'){
            var year = this.sesiones[0].node.fecha.substring(0,4);
            var month = this.sesiones[0].node.fecha.substring(5,7);
            var day = this.sesiones[0].node.fecha.substring(8,10);
            this.descansos = this.sesiones[0].node.descansos.split(',');
            this.sesion_agrupacion = this.sesiones[0].node.agrupaciones.split('||');
            this.sesion_agrupacion.forEach(element => {
              let grupo = element.split('::');
              this.id_grupos.push(grupo[0]);
              this.cambiafecha(grupo,year,month,day);
            });
          }
          else{
            this.sesiones = [];
          }
          //console.log(this.id_grupos);
        }),
        catchError(this.handleError('sesiones', []))
    ).subscribe(() => {
      if (this.sesiones) {
        console.log(this.sesiones);
        this.ponerMetaetiquetas(
          'Sesion para el día ',
          'Agrupaciones',
          'https://carnaval.ondacadiz.es/assets/logo_carnaval_300.png'
        );
      }
    });
  }

  cambiafecha(valor:any,year:any,month:any,day:any){
    // Resta uno a la hora que pasamos y convierte a GTM y coge la sesion actual o
    // la  anterior dependiendo de esta hora es antes o despues de las 8 de la mañana
    // this.log ("Cambiafecha: valor"+valor+"year"+year+"mes"+month+"dia"+day);
    var valorgtm = "";
    if (valor[3].substring(0,2) == "00"){
      valorgtm = "23";
    }else{
      valorgtm = (parseInt (valor[3].substring(0,2))-1).toString();
    }
    //hasta aqui resta una hora a la fecha de actuacion
    valorgtm = valorgtm.length > 1 ? valorgtm + valor[3].substring(2,5) : '0'+valorgtm + valor[3].substring(2,5);
    //añade los minutos y le pone 2 digitos (01..09)
    let fecha2:Date;
    fecha2 = new Date(year+"-"+month+"-"+day+"T"+valorgtm+":00.000Z");
    //convierte a hora gtm
    if (valorgtm < "08:00"){ fecha2.setDate(fecha2.getDate() + 1); }
    // si estamos antes de las 8 de la mañana, cambia al día anterior
    // console.log(fecha2.toISOString());
    var hora = fecha2.getHours().toString();
    var minutos = fecha2.getMinutes().toString();
    hora =  hora.length > 1 ? hora : '0' + hora.toString();
    minutos = minutos.length >1 ? minutos : '0' + minutos.toString();
    //console.log("-->" + hora + ":" + minutos);
    this.hora1.push(hora + ":" + minutos);
  }
  
  cargarDetalleagrupacion (nombre:string,tid:string){
    if (this.debug) {console.log ("cargarDetalleagrupacion "+tid); }
    this.detalleagrupacion =[];
    this.detalleagrupacion_contenidos=[];
    //  let url = URL_SERVICIOS + "/app_carnaval_coac_ficha/"+nombre+"/all/"+tid;
    let url = this.host_carnaval + "/app_carnaval_detalle_fichas/"+tid;
    this.fotos =[];
    this.http.get<any> ( url ).pipe(
      map ( (data:any) => {
          this.detalleagrupacion.push(data);
          if (this.debug) { console.log("detalle");console.log(data); }
          this.fotos = this.fotos.concat(data.nodes[0].node.fotos);
      }),
      catchError(this.handleError('detalleagrupacion', []))  
    ).subscribe(() => {
      if (this.detalleagrupacion[0].nodes[0]) {
        this.ponerMetaetiquetas(
          this.detalleagrupacion[0].nodes[0].node.modalidad+' '+this.detalleagrupacion[0].nodes[0].node.categoria+': '+this.detalleagrupacion[0].nodes[0].node.titulo,
          this.detalleagrupacion[0].nodes[0].node.year+', '+this.detalleagrupacion[0].nodes[0].node.localidad+', '+this.detalleagrupacion[0].nodes[0].node.autorletra,
          this.detalleagrupacion[0].nodes[0].node.fotos
        );
      }
    });

    this.detalleagrupacion_contenidos=[];
    // let url_videos = URL_SERVICIOS + "/app_carnaval_tv_videos_relacionados/"+tid;
    let url_videos = this.host_carnaval + "/app_carnaval_contenidos_detalle_ficha/"+tid;
    if (this.debug) { console.log (url_videos);  }
    this.http.get<any> ( url_videos ).pipe(
      map ( (data_videos:any) => {
        this.detalleagrupacion_contenidos.push(data_videos);
        if (this.debug) {
          console.log(data_videos.nodes[0].node.foto_galeria_original);
          console.log(data_videos.nodes.length);
        }
        if (data_videos.nodes.length > 2){this.boton=true;}else{this.boton=false;}
        this.detalleagrupacion_contenidos.forEach((item,key)=>{
          //console.log(this.detalleagrupacion_contenidos[key].nodes);
          this.detalleagrupacion_contenidos[key].nodes.forEach((item33:any)=>{
            this.fotos = this.fotos.concat(item33.node.foto_original);
            if (item33.node.descripcion === "") {this.haycronica = false;} else{this.haycronica=true;}
            if (item33.node["audio"]=== ""){this.hayaudio = false;}else{this.hayaudio=true;}
            if (item33.node.foto_galeria_medio === ""){this.hayfotos =false;} else {this.hayfotos=true;}
            if (item33.node.link === ""){this.hayvideos =false;} else {this.hayvideos=true;}
            item33.node.foto_galeria_medio = item33.node.foto_galeria_medio.split("||");
            let aux = item33.node.foto_galeria_original.split("||");
            item33.node.foto_galeria_original = aux;
            this.fotos = this.fotos.concat(aux);
          });      
        });       
      }),
      catchError(this.handleError('detalleagrupacion', []))
    ).subscribe()
  }

  cargarContenidosportada (){
    if (this.debug) {console.log ("cargarContenidosportada"); }
    this.contenidos_portada=[];
    let url_videos = this.host_carnaval + "/app_carnaval_contenidos_portada_pases";
    this.http.get<any> ( url_videos ).pipe(
      map ( (data_videos:any) => {
        this.contenidos_portada=data_videos.nodes;
        if (this.debug) {console.log(data_videos);}
        //if (data_videos.nodes.length > 2){this.boton=true;}else{this.boton=false;}   
          //console.log(this.detalleagrupacion_contenidos[key].nodes);
          this.contenidos_portada.forEach((item33:any)=>{
            this.fotos = this.fotos.concat(item33.node.foto_original);
            //if (item33.node.descripcion === "") {this.haycronica = false;} else{this.haycronica=true;}
            //if (item33.node["audio"]=== ""){this.hayaudio = false;}else{this.hayaudio=true;}
            if (item33.node.foto_galeria_medio === ""){this.hayfotos =false;} else {this.hayfotos=true;}
            if (item33.node.link === ""){this.hayvideos =false;} else {this.hayvideos=true;}
            item33.node.foto_galeria_medio = item33.node.foto_galeria_medio.split("||");
            let aux = item33.node.foto_galeria_original.split("||");
            item33.node.foto_galeria_original = aux;
            this.fotos = this.fotos.concat(aux);
          });          
      }),
      catchError(this.handleError('contenidoportada', []))
    ).subscribe();
    const swiperEl = document.getElementById('swiper');
    if (swiperEl){
      Object.assign(swiperEl, this.swiperParams1);
    }
    const swiperEl2 = document.getElementById('swiper2');
    if (swiperEl2){
      Object.assign(swiperEl2, this.swiperParams2);
    }
  }


  cargarVideoscoac(yyyymmdd:string,modalidad:string,categoria:string,title:string){
    if (this.debug) {console.log ("cargarVideoscoac"); }
    // 23,24,25,26,27 id de la taxonomias chirigita, comparsa, etc
    // 185, 186, 187 adulto, juvenil, infantil
    this.videoscoac =[];
    if (title != ""){ title = "?title="+title; }
    // if (this.debug) {console.log ("/app_carnaval_tv_carta/"+fecha+"/"+modalidad+categoria+title);}
    let url_videos = this.host_carnaval + "/app_carnaval_contenidos_sesiones_coac/"+yyyymmdd+"/"+modalidad+"/"+categoria+title;
    if (this.debug) {console.log ("Pidiendo: " + url_videos);}
    this.http.get<any> ( url_videos )
    .pipe(
      map ( (data_videos:any) => {
           this.videoscoac.push(data_videos.nodes);
        },
      ),
      catchError(this.handleError('videos sesion', []))
    ).subscribe(resp=>{
      this.videoscoac[0].forEach((element:any,key:any) => {
        this.fotos_original[key]=element.node.foto_galeria_original.split('||');
        this.fotos_medio[key]=element.node.foto_galeria_medio.split('||');
      });
    });
  }


  async cargarFiltrosAgrupaciones(){
    if (this.debug) {console.log ("cargarFiltrosAgrupaciones"); }
    this.filtrosagrupaciones =[];
    let aux:any=[];
    let url = this.host_carnaval + "/app_carnaval_filtros/agrupaciones";
    return this.http.get<any> ( url )
      .pipe(
        map( (resp:any) => {
          // console.log(resp);
          aux.push(...resp.dates);
          this.filtrosagrupaciones = aux.reverse();
          if (this.debug) { console.log(this.filtrosagrupaciones);}
          // resolve();
          } ),
        catchError(this.handleError('filtro agrupaciones', []))
      )
      .subscribe();
  }
    
  cargarAgrupaciones (agno:string,modalidad:string){
      if (this.debug) {console.log ("cargarAgrupaciones"); }
      this.agrupaciones = [];
      this.cuartetos = [];
      this.coros = [];
      this.comparsas = [];
      this.chirigotas = [];
    
      this.coros_a = [];
      this.comparsas_a = [];
      this.chirigotas_a = [];
      this.cuartetos_a = [];
    
      this.coros_j = [];
      this.comparsas_j = [];
      this.chirigotas_j = [];
      this.cuartetos_j = [];
    
      this.coros_i = [];
      this.comparsas_i = [];
      this.chirigotas_i = [];
      this.cuartetos_i = [];
    
      //let url = URL_SERVICIOS + "/app_carnaval_coac_falla/adulto/2018-01-09";
      //let url = URL_SERVICIOS + "/app_carnaval_coac_agrupaciones/"+agno+"/all";
      let url = this.host_carnaval + "/app_carnaval_fichas/"+agno;
      this.http.get ( url ).pipe(
        map( (data:any) => {
          if (this.debug) {console.log(data);}
          this.agrupaciones.push(data);
          if (this.agrupaciones[0].nodes){
            this.agrupaciones[0].nodes.forEach((item:any,key:any)=>{
              //console.log("filtro:"+item.node.title);
              if (item.node.modalidad == 'Cuarteto'){
                this.cuartetos.push(item.node);
                if (item.node.categoria == 'Adulto'){ this.cuartetos_a.push(item.node);}
                if (item.node.categoria == 'Juvenil'){ this.cuartetos_j.push(item.node);}
                if (item.node.categoria == 'Infantil'){ this.cuartetos_i.push(item.node);}
              };
              if (item.node.modalidad == 'Chirigota'){
                this.chirigotas.push(item.node);
                if (item.node.categoria == 'Adulto'){ this.chirigotas_a.push(item.node);}
                if (item.node.categoria == 'Juvenil'){ this.chirigotas_j.push(item.node);}
                if (item.node.categoria == 'Infantil'){ this.chirigotas_i.push(item.node);}
              };
              if (item.node.modalidad == 'Coro'){
                this.coros.push(item.node);
                if (item.node.categoria == 'Adulto'){ this.coros_a.push(item.node);}
                if (item.node.categoria == 'Juvenil'){ this.coros_j.push(item.node);}
                if (item.node.categoria == 'Infantil'){ this.coros_i.push(item.node);}
              };
              if (item.node.modalidad == 'Comparsa'){
                this.comparsas.push(item.node);
                if (item.node.categoria == 'Adulto'){ this.comparsas_a.push(item.node);}
                if (item.node.categoria == 'Juvenil'){ this.comparsas_j.push(item.node);}
                if (item.node.categoria == 'Infantil'){ this.comparsas_i.push(item.node);}
              };
            });
          }
        }),
        catchError(this.handleError('agrupaciones', []))
      )
      .subscribe (() => {
        if (this.detalleagrupacion[0].nodes[0]) {
          this.ponerMetaetiquetas(
            'Agrupaciones del Carnaval de Cádiz',
            'Fichas informativas de todas las agrupaciones participantes en el Concurso Oficial de Agrupaciones del Carnaval de Cádiz (COAC) desde 2010. Contiene los datos de la agrupación, fotos, vídeos y más.',
            'https://carnaval.ondacadiz.es/assets/logo_carnaval_300.png'
          );
        }
      });
    }


  async openVideo(video:any){
    if ((this.platform.is('android'))  && (false)){
      // this.youtube.openVideo(video.slice(-11));
    }else{
      const modal = await this.modalCtrl.create({
        component:ModalVideoPage,
        componentProps:{
          video:video.slice(-11)
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      //console.log(data);
    }
  }


  async verImagengaleria(galeria:any, index:number){
    //console.log(galeria);
    const modal = await this.modalCtrl.create({
      component: ModalImagenComponent,
      cssClass: "fullscreen",
      componentProps: {
        galeria:galeria,
        index:index,
        tipofiltro:"galeria"        
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    //console.log(data);
  }


  async abrirPubli(publi:any){
    if (this.debug) {console.log("en abrir publi");}
    this.observablePublicidad.unsubscribe();
    this.observablePublicidad.remove(this.observablePublicidad);

    const modal = await this.modalCtrl.create({
      component: ModalPubliComponent,
      cssClass: "fullscreen",
      componentProps: {
        publi:publi,      
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    //console.log(data);
  }

  public log(message: string) {
    if (this.debug) {console.log (message);}
        this.messageService.add(`App: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => { 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
        //let alert = this.alertCtrl.create({
        //  title: "Error",
        //  subTitle: "La consulta de días de sesiones (filtros) falló",
        //  buttons:["OK"]
        //});
        //alert.present();
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  cargarNoticiasCarnaval(nid:string ='all',year:string='all'){
    this.noticia_detalle_item=[];
    let url_noticias = this.host + "/app_carnaval_actualidad/" + nid + "/" + year;
    this.http.get<any>(url_noticias).pipe(
      map ( (data:any) => {
        if (nid=='all'){
          this.noticia_item = data.nodes;
        }else{
          this.noticia_detalle_item = data.nodes;   
        }
      }),
      catchError(this.handleError('detallenoticias', []))  
    ).subscribe(() => {
      if (this.noticia_detalle_item[0]) {
        this.ponerMetaetiquetas(
          this.noticia_detalle_item[0].node.title,
          this.noticia_detalle_item[0].node.subtitle,
          this.noticia_detalle_item[0].node.mainimage
        );
      }
    });
  }

  cargarVideospopurri(fecha:string = 'selected',title:string = ''){
    this.videospopurri =[];
    if (title != ""){
      title = "?title="+title;
    }
    if (this.debug) {console.log ("/app_carnaval_popurri/"+fecha);}
    let url = this.host_carnaval + "/app_carnaval_popurri/" + fecha + title;
    this.http.get<any>(url).pipe(
      map ( (data:any) => {
        this.videospopurri = data.nodes;
        if (this.debug) { console.log(this.videospopurri);}
      }),
      catchError(this.handleError('detallenoticias', []))  
    ).subscribe();
  }

  cargarProgramasTvCarnaval(fecha:string ='selected'){
    this.programastv = [];
    let url_programas = this.host + "/app_carnaval_prg_tv/" + fecha;
    if (this.debug) {console.log (url_programas);}
    this.http.get<any>(url_programas).pipe(
      map ( (data:any) => {
        this.programastv = data.nodes;
        if (this.debug) {console.log(this.programastv);}
      }),
      catchError(this.handleError('programas tv', []))  
    ).subscribe();
  }
  
  cargarEventos(nid:string = 'all'){
    let elemento:any=[];
    let url_eventos ="";
    if (nid == 'all')
    {
      this.eventos_item=[];
      url_eventos = this.host + "/app_carnaval_agenda_meses";
    }else{
      this.eventos_detalle_item=[];
      url_eventos = this.host + "/app_carnaval_agenda/" + nid;
    }
     this.http.get<any>(url_eventos).pipe(
      map ( (data:any) => {
         elemento = data.nodes;
            elemento.forEach((item:any,key:any)=>{
              if (item.node.datefrom == item.node.dateto){
                item.node.fechadefinitiva = item.node.datefrom;
              }else{
                item.node.fechadefinitiva = "Del " + item.node.datefrom + " al " + item.node.dateto;
              }
              if (item.node.hour !== ""){
                item.node.horadefinitiva = " - " + item.node.hour;
              }else{
                if (item.node.hourfrom == item.node.hourto){
                  if (item.node.hourto == "00:00"){
                    item.node.horadefinitiva = "";
                  }else{
                    item.node.horadefinitiva = " - " + item.node.hourfrom;
                  }
                }else{
                  item.node.horadefinitiva = " De " + item.node.hourfrom + " a " + item.node.hourto;
                }
              }
            });
            if (this.debug) {console.log (elemento);}
          if (nid == 'all'){
            this.eventos_item = elemento;  
          }else{
            this.eventos_detalle_item = elemento;
          }          
        }),
      catchError(this.handleError('detallenoticias', []))  
    ).subscribe(() => {
      if (this.eventos_detalle_item) {
        this.ponerMetaetiquetas(
          this.eventos_detalle_item[0].node.title,
          this.eventos_detalle_item[0].node.location,
          this.eventos_detalle_item[0].node.thumbimage
        );
      }
    });
  }

  cargarRanking(dia:string){
    if (this.debug) {console.log ("cargarRanking"); }
    this.ranking =[];
    this.rankingcuartetos =[];
    this.rankingchirigotas =[];
    this.rankingcoros =[];
    this.rankingcomparsas =[];
    //let url = URL_SERVICIOS + "/app_carnaval_coac_falla/adulto/2018-01-09";
    let url = this.host_carnaval + "/app_carnaval_votaciones/ranking/"+dia+"/all";
    if (this.debug) { console.log(url); }
    this.http.get<any>( url ).pipe(
        map( data => {
          if (this.debug) { console.log(data); }
          this.ranking.push(...data.nodes);
          this.ranking.forEach((item,key)=>{

             if (item.node.grouptype == 'Cuarteto'){
               this.rankingcuartetos.push(item);
             };
             if (item.node.grouptype == 'Chirigota'){
               this.rankingchirigotas.push(item);
             };
             if (item.node.grouptype == 'Coro'){
               this.rankingcoros.push(item);
             };
             if (item.node.grouptype == 'Comparsa'){
               this.rankingcomparsas.push(item);
             };
           });
           if (this.debug) { console.log(this.rankingcuartetos);}
        }),
        catchError(this.handleError('ranking', []))  
      ).subscribe();
  }

  cargarItemsparavotos (){
    if (this.debug) {console.log ("cargarItemsparavotos"); }
    this.itemsparavotos=[];
    let respuesta: any;
    // let url = URL_SERVICIOS + "/app_carnaval_votaciones/disponibles";
    let url = this.host_carnaval + "/app_carnaval_votaciones/disponibles";
    this.http.get<any>( url ).pipe(
      map( (data:any) => {
        respuesta = data;
           if (this.debug) {console.log(respuesta.message);}
           if (respuesta.message == 'ko'){
            // let alert = this.alertCtrl.create({
            //   title: "Información",
            //   subTitle: "Actualmente no hay agrupaciones disponibles para votar",
            //   buttons:["OK"]
            // })
            // alert.present();
           }else{
             this.itemsparavotos.push(data);
           }
        }),
        catchError(this.handleError('items para votos', []))  
      ).subscribe();

  }

  async votarItem (item:number){
    //console.log(galeria);
    this.disableButton = true;
    const modal = await this.modalCtrl.create({
      component: ModalVotacionesComponent,
      cssClass: "fullscreen",
      componentProps: {
        item:item     
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }  


  
  async mostrarMensaje(mensaje1:string, mensaje2:string){
    const modal = await this.modalCtrl.create({
      component: ModalMensajeComponent,
      cssClass: "quarterscreen",
      componentProps: {
        mensaje1:mensaje1,
        mensaje2:mensaje2     
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (this.debug) { console.log(data); }
    if (data.dismissed) {
      this.disableButton = false;
    }else{
      //return this.modalCtrl.dismiss({'dismissed':true });
    }
  }
  

  votarItem2 (url2:string):Promise<any>{
    if (this.debug) {console.log ("votarItem"); }
    return new Promise((resolve,reject) => {
    this.resultado =[];
    //  let url = URL_SERVICIOS + "/app_carnaval_galerias_imagenes/selected;
    let url = this.host_carnaval + "/app_carnaval_votaciones/"+url2;
    if (this.debug) { console.log (url);}
    this.http.get<any>( url ).pipe(
      map( data => {
           this.resultado.push(data);
           //console.log ("resultado "+data['text']);
           resolve({er:data['text']});
        }),
        catchError(this.handleError('El voto ha fallado', []))  
      ).subscribe();
    });
  }

  onVoto(valor:number,id_agrup:number, titulo:string, modalidad:string){
    this.disableButton = true;
    let texto1:string = "";
    let texto2:string = "";
    let plataforma:string = "";
    if (this.platform.is('android')){
      plataforma='android';
    }else if (this.platform.is('ios')){
      plataforma='ios';  
    }else if (this.platform.is('desktop')){
      plataforma='desktop';
    }else{
      plataforma='otra';
    }
    
    const uuid = Device.getId().then((deviceId) => {
      this.deviceId = deviceId.identifier;
      let fecha2 = new Date();
      let mensaje = "votar/"+valor+"/"+id_agrup+"/"+titulo+"/"+modalidad+"/"+deviceId.identifier+"1/"+plataforma;
      this.votarItem2(mensaje).then(re => {
        if (this.debug) { console.log (re);}
        if (re.er == "votacion correcta"){
          if (Date.parse(fecha2.toISOString()) - this.ajustes.votos.fecha > 86400000){
              this.ajustes.votos.fecha = Date.parse(fecha2.toISOString());
          }
          if (valor==1) {this.ajustes.votos.uno=true;}
          if (valor==2) {this.ajustes.votos.dos=true;}
          if (valor==3) {this.ajustes.votos.tres=true;}
          if (valor==4) {this.ajustes.votos.cuatro=true;}
          if (valor==5) {this.ajustes.votos.cinco=true;}
          this.guardar_storage();
          texto1= "Votación correcta";
          texto2 = "Has dado "+valor+" puntos a la agrupación "+titulo;
        }else{
          texto1= "Error";
          texto2 = re.er;
        }
        this.mostrarMensaje(texto1, texto2);
      });
    });
    
  }

  comprobarPubli(pagina:string){
    // valores posibles de pagina segun ocadizdigital
    //app-carnaval-todas, app-carnaval-inicio, app-carnaval-tv-oc, app-carnaval-tv-carta,
    //app-carnaval-carta-videoscoac, app-carnaval-carta-audioscoac, app-carnaval-coac-sesiones,
    //app-carnaval-coac-galeria, app-carnaval-coac-agrupaciones, app-carnaval-actualidad-noticias
    //app-carnaval-coac-agenda, app-carnaval-participa-votaciones, app-carnaval-participa-ranking,
    //app-carnaval-carteles, app-carnaval-enlaces,
    // NO NO app-carnaval-favoritos, app-carnaval-apps, app-carnaval-coac-cronicas, 
    let aux =<any>[];
  //    console.log("comprobando publi en "+pagina);
    let tiempo = 0;
  //  this.cargar_storage();
    let index=0;
    //  console.log ("entro en comprobar publi");
    if (this.ajustes.publicidades == undefined || this.ajustes.publicidades[0] == undefined || this.ajustes.publicidades[0][0] == undefined){
      if (this.debug) {console.log("ajustes.publicidades es undefined");}
    }else{
      for (let item of this.ajustes.publicidades[0]){
        let posiciones = item.node.posicion.split(", ");
        //  if ((item.node.posicion == pagina) || (item.node.posicion == "app-carnaval-todas")){
        if ((posiciones.includes(pagina)) || (posiciones.includes("app-carnaval-todas"))){
          if (this.debug) {console.log ("si"); console.log (item.node.posicion);}
          if ((pagina == "app-carnaval-tv-oc") || (pagina == "app-carnaval-radio-oc")){
            tiempo = 1000;
          } else{
            tiempo = 4000; //item.node.timein*1000;
          }
          this.observablePublicidad.unsubscribe();
          this.observablePublicidad.remove(this.observablePublicidad);
          this.observablePublicidad = interval(tiempo).subscribe(()=>{

            aux.push(this.ajustes.publicidades[0][index]);
           // this.abrirPubli(item);
          });
          break;
        }
        index=index+1;
      }
    }
  }

  pararPubli(){
    //console.log(this.observablePublicidad);
    //this.observablePublicidad.unsubscribe();
  }


  getPrograma(programa_id:number){
      const url_fav = this.host + '/app_liveoc_datos_programa/' + programa_id; //10
     // console.log ("pidiendo programa"+url_fav);
      return this.http.get<iprogramas>(url_fav);
  }

  getCapitulo(capitulo_id:number){
    const url_fav = this.host + '/app_liveoc_datos_capitulo/' + capitulo_id; //10
   // console.log ("pidiendo programa"+url_fav);
    return this.http.get<icapitulos>(url_fav);
  }
/*
  getFichaagrupacion(nid:number){
    //const url_ficha = this.host_carnaval + '/app_carnaval_contenidos_detalle_ficha/' + nid;
    const url_ficha = this.host_carnaval + "/app_carnaval_detalle_fichas/" + nid;
    console.log (url_ficha);
    return this.http.get<any>(url_ficha);
  }
*/

  cargarBasicas(nid:any) {
    const url = this.host + '/app_liveoc_pages/' + nid;
    return this.http.get<any>( url );
  }

  get_cartelescarnaval(){
    const url_cartelescarnaval = this.host_cofrade + '/app_carnaval_carteles_original/'; //exportaciones 3 -- videos coac
    this.http.get<any>( url_cartelescarnaval ).pipe(
      map( (data:any) => {
      //  console.log(data);
        this.carteles = data;
        }),
        catchError(this.handleError('carteles', []))  
      ).subscribe( () => {
        if (this.carteles) {
          this.ponerMetaetiquetas('Carteles del Carnaval de Cádiz',
            'Carteles anunciadores del Carnaval de Cádiz. De interés turístico Internacional.',
            'https://contenidos.ondacadiz.es'+this.carteles[0].fotos
          );
        }
      });
   // return this.http.get<any>(url_cartelescarnaval);
  }

  getVideoId(url:string) {
    var video, match;
    if (url === null) {
        return '';
    }
    var regExp = /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^\?&\"'>]+)/;
    match = url.match(regExp);
    video = (match&&match[1].length==11)? match[1] : false;
  //  console.log ("id del video "+video);
    return video;
  }

  getCookie(name:string):string|null{
    const nameLenPlus = (name.length +1);
    return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`; 
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
  }

  sinAcentosNiEspacios (texto:string):string{
    let de = 'áãàäâéëèêíïìîóöòôúüùûñç',
         a = 'aaaaaeeeeiiiioooouuuunc',
        re = new RegExp('['+de+']' , 'ug');
    let result = texto.replace(re,  match => a.charAt(de.indexOf(match)));
    //console.log(result);
    return result;     
  } 

  ir_a_noticia(item:any):void{
    //this.noticia_item = item;
    if (this.debug) {console.log(this.noticia_item);}
    this.router.navigate(['/carnaval/noticia',item.nid]);
  }

  ir_a_evento(item:any):void{
    //console.log(this.eventos_item);
    this.router.navigate(['/carnaval/agenda',item.nid]);
  }

  ir_a(item:any):void{
    //console.log(item);
    if (item.stream){
      if (this.debug) { console.log(item); }
      this.capitulo_item =  item;
      this.router.navigate(['/canal', item.nid]); 
    }else if (item.url_publi){
      window.open(item.url_publi, "_blank");
    }else if (item.linknimble){
      item.stream = 'nimble';
      item.link = item.linknimble;
     // console.log(item);
      this.capitulo_item =  item;
      this.router.navigate(['/canal', item.nid]);      
    }else if (item.programa){
      //console.log("capitulo");
      this.capitulo_item =  item;
      this.router.navigate(['/capitulo', item.nid, 'tv']);
    }else if (item.video_cof_link){ 
      //console.log("carnaval");
      this.capitulo_item =  item;
      this.router.navigate(['/capitulo', item.nid, 'cofrade']);      
    }else if (item.weblink){ 
      //console.log("carnaval");
      this.capitulo_item =  item;
      this.router.navigate(['/capitulo', item.nid, 'carnaval']); 
    }else{
      //console.log("programa");
      this.programa_item =  item;
      this.router.navigate(['/programa', item.nid]);
    }
  }

  cargarEnlaces (){
    if (this.debug) {console.log ("cargarEnlaces"); }
    this.enlaces =[];
    let horaactual = new Date();
    //let url = URL_SERVICIOS + "/app_carnaval_micarnaval_enlaces";
    let url = this.host_carnaval + "/app_carnaval_enlaces";
    this.http.get<any>(url).pipe(
      map ( (data:any) => {
        if (this.debug) { console.log(data.nodes); }
        this.enlaces = data.nodes;
        this.enlaces[0].hora = Date.parse(horaactual.toISOString());
        localStorage.setItem('enlaces',JSON.stringify(this.enlaces));
        //console.log(this.programastv);
      }),
      catchError(this.handleError('enlaces', []))  
    ).subscribe();
  }
  
  async iraEnlace(enlace:string){
    await Browser.open({ url: enlace });
 }

  cargar_storage(){
    if (this.debug) { console.log('cargar storage'); }
    if( this.platform.is("cordova")){
      //dispositivo
      this.ajustes = JSON.parse( localStorage.getItem("ajustes") || '{}');
    }else{
      //escritorio
      if (localStorage.getItem("ajustes")){
        this.ajustes = JSON.parse( localStorage.getItem("ajustes") || '{}');
      }
    }
  }

  guardar_storage(){
    if (this.debug) { console.log('guardar storage'); }
    if( this.platform.is("cordova")){
      localStorage.setItem("ajustes", JSON.stringify(this.ajustes));
    }else{
      //escritorio
      localStorage.setItem("ajustes", JSON.stringify(this.ajustes));
    }
  }

}
