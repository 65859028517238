<ion-content class="modalVotaciones">
  <div class="contenido">
    <ion-buttons slot="end">
      <ion-button (click)="cerrarModal()">
        <ion-icon  name="close-circle" size="large" float-end></ion-icon>
      </ion-button>
    </ion-buttons>
    <div class="votos">
      <p>-{{item.grouptype}}-</p>
      <h2 text-center>{{item.title}}</h2>
      <p>{{item.author}}</p>

      <ion-grid>
        <ion-row>
          <ion-label col-12 text-center>Puntuación</ion-label>
        </ion-row>
        <ion-row>
          <ion-col size-md="1" size-sm="1" size-xs="1"></ion-col>
          <ion-col size-md="2" size-sm="2" size-xs="2">
            <button *ngIf="puntos != 1 && uno == false" col-2 (click)="puntos = 1; service.disableButton = false;"><img src="assets/icon/votar1_off.png"></button>
            <button *ngIf="puntos == 1 && uno == false" col-2 (click)="puntos = 0; service.disableButton = true;"><img src="assets/icon/votar1_on.png"></button>
            <button *ngIf="uno == true" col-2><img src="assets/icon/votar0.png"></button>
          </ion-col>
          <ion-col size-md="2" size-sm="2" size-xs="2">
            <button *ngIf="puntos != 2 && dos == false" col-2 (click)="puntos = 2; service.disableButton = false;"><img src="assets/icon/votar2_off.png"></button>
            <button *ngIf="puntos == 2 && dos == false" col-2 (click)="puntos = 0; service.disableButton = true;"><img src="assets/icon/votar2_on.png"></button>
            <button *ngIf="dos == true" col-2><img src="assets/icon/votar0.png"></button>
          </ion-col>
          <ion-col size-md="2" size-sm="2" size-xs="2">
            <button *ngIf="puntos != 3 && tres == false" col-2 (click)="puntos = 3; service.disableButton = false;"><img src="assets/icon/votar3_off.png"></button>
            <button *ngIf="puntos == 3 && tres == false" col-2 (click)="puntos = 0; service.disableButton = true;"><img src="assets/icon/votar3_on.png"></button>
            <button *ngIf="tres == true" col-2><img src="assets/icon/votar0.png"></button>
          </ion-col>
          <ion-col size-md="2" size-sm="2" size-xs="2">
            <button *ngIf="puntos != 4 && cuatro == false" col-2 (click)="puntos = 4; service.disableButton = false;"><img src="assets/icon/votar4_off.png"></button>
            <button *ngIf="puntos == 4 && cuatro == false" col-2 (click)="puntos = 0; service.disableButton = true;"><img src="assets/icon/votar4_on.png"></button>
            <button *ngIf="cuatro == true" col-2><img src="assets/icon/votar0.png"></button>
          </ion-col>
          <ion-col size-md="2" size-sm="2" size-xs="2">
            <button *ngIf="puntos != 5 && cinco == false" col-2 (click)="puntos = 5; service.disableButton = false;"><img src="assets/icon/votar5_off.png"></button>
            <button *ngIf="puntos == 5 && cinco == false" col-2 (click)="puntos = 0; service.disableButton = true;"><img src="assets/icon/votar5_on.png"></button>
            <button *ngIf="cinco == true" col-2><img src="assets/icon/votar0.png"></button>
          </ion-col>
          <ion-col size-md="1" size-sm="1" size-xs="1"></ion-col>
          <p></p>
          <p *ngIf="puntos != 0;">Vas a asignar {{puntos}} puntos a esta agrupación</p>
          <p *ngIf="puntos == 0;">Pulsa sobre la puntuación que quieres otorgar</p>
          <p></p>
        </ion-row>

        <ion-row>
          <ion-col col-4></ion-col>
          <!--button col-4 (click)="onVoto(puntos,itemparavotos.title)"><img src="assets/icons/bt_votar_off.png"></button-->
          <button col-4 class="votar" [disabled]="this.service.disableButton" (click)="this.service.onVoto(puntos,item.id_agrup,item.title,item.grouptype)">Votar</button>
          <ion-col col-4></ion-col>
        </ion-row>
        <p></p>
      </ion-grid>

      </div>

  </div>
</ion-content>