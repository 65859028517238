import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServicioCarnavalService } from './../../serviciocarnaval.service';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-programas-tv',
  templateUrl: './programas-tv.html',
  styleUrls: ['./programas-tv.scss'],
})
export class ProgramasTvComponent  implements OnInit, OnDestroy {

  filtro:any='';
  listafechas:any=[];
  constructor(public service: ServicioCarnavalService, private readonly titleService: Title) {
    const fecha = new Date();
    let agno = fecha.getFullYear();
    for (let i=agno; i>=2010; i--){
      this.listafechas.push(i);
    }
   }

  ngOnInit() {
    this.service.setearpantallaFB("ProgramasTV2","ProgramasTVScreen2");
    this.service.cargarProgramasTvCarnaval();
    this.service.comprobarPubli("app-carnaval-tv-carta");
    // this.firebaseAnalytics.logEvent('page_view', {page: "sesiones"});
  }

  ngOnDestroy(): void {
  //  this.service.observablePublicidad.unsubscribe();
  //  this.service.observablePublicidad.remove(this.service.observablePublicidad);
  }

  filterData (valor:any,tipo:any){
    this.service.seteareventoFB("filtro_programastv_agno2",valor,"");
    // console.log(valor, tipo);
    this.service.cargarProgramasTvCarnaval(valor);
  }

  busqueda(){
    this.service.seteareventoFB("filtro_programastv_busqueda2","busqueda2","");
  }
  
}
