<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Carteles</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="row content">
    <ion-card class="card col-md-4 col-sm-6" *ngFor="let item of this.service.carteles">
      <h3>{{item.titulo}}</h3>
      <ion-card-content><img class='img-fluid' src='{{service.host_carnaval}}{{item.fotos}}' alt="cartel {{item.titulo}}"></ion-card-content>
    </ion-card>
  </div>
</ion-content>