import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(value: Array<any>, args: string =''): Array<any> {
    const resultPost = [];
    for (const post of value){
      if (post.node.titulo){
        if ( (post.node.descripcion.toLowerCase().indexOf(args.toLowerCase()) > -1) || (post.node.titulo.toLowerCase().indexOf(args.toLowerCase()) > -1)){        
          resultPost.push(post);
        };
      }else{
        if ( (post.node.description.toLowerCase().indexOf(args.toLowerCase()) > -1) || (post.node.title.toLowerCase().indexOf(args.toLowerCase()) > -1)){        
          resultPost.push(post);
        };
      }

    };
    return resultPost;
  }
}