import { Injectable } from '@angular/core';
import { ModalController} from '@ionic/angular';
// import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { initializeApp } from 'firebase/app';
import { getInstallations, getId } from 'firebase/installations';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { ModalMensajeComponent } from './paginas/modal-mensaje/modal-mensaje';


@Injectable({
  providedIn: 'root'
})
export class ServiciopushService {

  constructor(private modalCtrl:ModalController) { }

  public async initPush() {
    try{
      await this.addListerners();
      this.getDeliveredNotifications();
    } catch(e){
      console.log(e);
    } 
  }

  async getDeliveredNotifications(){
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notification',notificationList);
  }

  async addListerners(){

    console.log('Inicializando listener notificaciones');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt'){
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted'){
      throw new Error('el usuario no dio permiso');
    }
    await PushNotifications.register();

      /*
      await PushNotifications.addListener('registration', token => {
        console.log ('mi token: ',token);
        const fcm_token = (token?.value);
        let go = 1;
        const saved_token = localStorage.getItem('FCM_TOKEN');
        if (saved_token){
          if(fcm_token == saved_token){
            console.log ('same token');
            go = 0;
          }else{ go = 2; }
        }
        if (go == 1){
          localStorage.setItem('FCM_TOKEN', fcm_token);
        } else if (go == 2){
          const data = {
            expired_token: saved_token,
            refreshed_token: fcm_token
          };
          localStorage.setItem('FCM_TOKEN', fcm_token);
        }
        console.info('Registration token: ', token.value);
      });
      */
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.log(token.value);
          //alert('Push registration success, 2token: ' + token.value);
        }
      );
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          alert('Error on registration: ' + JSON.stringify(error));
        }
      );
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
         //alert('Push received: ' + JSON.stringify(notification));
         this.mostrarMensaje('App:', JSON.stringify(notification));
         }
      );
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          alert('Push action performed: ' + JSON.stringify(notification));
        }
      );

    }

    public async initSW(){
      // Inicializa Firebase
      const firebaseApp = initializeApp(environment.firebaseConfig);
      // Inicializa Firebase Cloud Messaging (FCM)
      const messaging = getMessaging(firebaseApp);    
      // Obtener el FID
      const installations = getInstallations(firebaseApp);
      getId(installations).then((fid) => {
        console.log('FID de la instalación:', fid);
        //this.mostrarMensaje('App: ', fid);
      }).catch((error) => {
        console.error('Error al obtener el FID:', error);
      });
      
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('firebase-messaging-sw.js')
          .then((registration) => {
            console.log('Service Worker registrado con éxito:', registration);
          })
          .catch((err) => {
            console.error('Error al registrar el Service Worker:', err);
          });
      }
      
      // Solicita el token de notificación para web push
      getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Token para notificaciones web:', currentToken);
            // Aquí puedes enviar este token a tu servidor si lo necesitas
          } else {
            console.log('No se pudo obtener el token de notificaciones web');
          }
        })
        .catch((err) => {
          console.log('Error al obtener el token:', err);
        });
      
      // Manejar las notificaciones entrantes
      onMessage(messaging, (payload) => {
        console.log('Notificación recibida:', payload);
        this.mostrarMensaje('App: ', JSON.stringify(payload));
        // Aquí puedes mostrar la notificación en la web
      });
    }

    async mostrarMensaje(mensaje1:string, mensaje2:string){
      const modal = await this.modalCtrl.create({
        component: ModalMensajeComponent,
        cssClass: "quarterscreen",
        componentProps: {
          mensaje1:mensaje1,
          mensaje2:mensaje2     
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data.dismissed) {
       // this.disableButton = false;
      }else{
        //return this.modalCtrl.dismiss({'dismissed':true });
      }
    }
  }