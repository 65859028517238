<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Programas TV</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    
    <ion-item>
      <ion-grid>
        <ion-row>
          <ion-col size-md="6" size-sm="6" size-xs="5">
            <ion-select class="categoria-filtro" interface="popover" [value]="listafechas[0]" #fechas (ionChange)="filterData(this.fechas.value,'')" >
              <div slot="label">Año:</div>    
              <ion-select-option [value]="fechas"  *ngFor="let fechas of listafechas; let i=index;">
                    {{fechas}}
              </ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size-md="6" size-sm="6" size-xs="7">
            <ion-searchbar color="light" class="ion-no-padding" [(ngModel)]="filtro" (change)="filtro=filtrado.value; busqueda();" #filtrado mode="ios" [placeholder]="'Buscar '">
            </ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>

    <ion-grid>
      <ion-row>
        <ion-col size-lg="4" size-md="6" size-sm="12" size-xs="12" (click)="service.openVideo(sesion.node.link)" *ngFor="let sesion of service.programastv | filter:filtro">
          <ion-card>
            <div class="mostrar-sm" > 
              <h3>{{sesion.node.title}}</h3>    
              <div class="etiqueta">{{sesion.node.nodedate}}</div>
            </div>

            <ion-row>
              <div class="etiqueta ocultar-sm">{{sesion.node.nodedate}}</div>
              <h3 class="ocultar-sm">{{sesion.node.title}}</h3>
                <span class="video">
                  <img class="img1" col-3 item-start [src]="sesion.node.link| miniaturavideo ">
                  <ion-icon class="play" name="play-circle" ng-reflect-ios="play-circle-outline" ng-reflect-md="play-circle-sharp"></ion-icon>
                </span>
                <p>       
                  {{sesion.node.description}}
                </p>
            </ion-row>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>
</ion-content>