<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Agrupaciones</ion-title>
    <div class="logo" slot="end"><ion-img src='assets/logo_carnaval_300.png'></ion-img></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row content">
    <ion-item>
      <ion-grid>
        <ion-row>
          <ion-col size-md="4" size-sm="4" size-xs="12">
            <ion-select class="categoria-filtro" interface="popover" value="{{service.filtrosagrupaciones[0]}}" #fechas (ionChange)="filterData(this.fechas.value,'all')">
              <div slot="label">Año:</div>    
              <ion-select-option [value]="fechas"  [selected]="i==9" *ngFor="let fechas of service.filtrosagrupaciones; let i=index;">
                    {{fechas}}
              </ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size-md="4" size-sm="4" size-xs="12">
            <ion-select class="tipoagrupacion" interface="popover" value="all" #tipoagrupacion >
              <div slot="label">Modalidad:</div>
              <ion-select-option value="all">Todos</ion-select-option>
              <ion-select-option value="Coros">Coros</ion-select-option>
              <ion-select-option value="Comparsas">Comparsas</ion-select-option>
              <ion-select-option value="Chirigotas">Chirigotas</ion-select-option>
              <ion-select-option value="Cuartetos">Cuartetos</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size-md="4" size-sm="4" size-xs="12">
            <ion-select class="categoria-filtro" interface="popover" value="all" #categoria2>
              <div slot="label">Categoría:</div>
              <ion-select-option value="all">Todos</ion-select-option>
              <ion-select-option value="adulto">Adulto</ion-select-option>
              <ion-select-option value="juvenil">Juvenil</ion-select-option>
              <ion-select-option value="infantil">Infantil</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </div>

  <span *ngIf="this.tipoagrupacion.value == 'Cuartetos'  && this.categoria2.value == 'all'">
    <ion-grid>
      <ion-row>
        <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.cuartetos;"> 
          <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
            <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
            <ion-card-content >
              <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
              <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
              <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
              <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </span>

  <span *ngIf="this.tipoagrupacion.value == 'Cuartetos'  && this.categoria2.value == 'adulto'">
    <ion-grid>
      <ion-row>
        <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.cuartetos_a;"> 
          <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
            <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
            <ion-card-content >
              <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
              <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
              <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
              <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </span>

    <span *ngIf="this.tipoagrupacion.value == 'Cuartetos'  && this.categoria2.value == 'juvenil'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.cuartetos_j;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
            <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
            <ion-card-content >
              <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
              <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
              <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
              <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
            </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Cuartetos'  && this.categoria2.value == 'infantil'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.cuartetos_i;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Chirigotas'   && this.categoria2.value == 'all'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.chirigotas;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Chirigotas'   && this.categoria2.value == 'adulto'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.chirigotas_a;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Chirigotas'   && this.categoria2.value == 'juvenil'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.chirigotas_j;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Chirigotas'   && this.categoria2.value == 'infantil'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.chirigotas_i;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Coros'  && this.categoria2.value == 'all'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.coros;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

    <span *ngIf="this.tipoagrupacion.value == 'Coros'  && this.categoria2.value == 'adulto'">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.coros_a;"> 
            <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
              <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
              <ion-card-content >
                <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                <p>{{agrupaciones.madalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </span>

     <span *ngIf="this.tipoagrupacion.value == 'Coros'  && this.categoria2.value == 'juvenil'">
        <ion-grid>
          <ion-row>
            <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.coros_j;"> 
              <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
                <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
                <ion-card-content >
                  <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                  <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                  <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                  <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </span>

      <span *ngIf="this.tipoagrupacion.value == 'Coros'  && this.categoria2.value == 'infantil'">
        <ion-grid>
          <ion-row>
            <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.coros_i;"> 
              <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
                <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
                <ion-card-content >
                  <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                  <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                  <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                  <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
       </span>

      <span *ngIf="this.tipoagrupacion.value == 'Comparsas' && this.categoria2.value == 'all'">
        <ion-grid>
          <ion-row>
            <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.comparsas;">
              <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
                <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
                <ion-card-content >
                  <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                  <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                  <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                  <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </span>

      <span *ngIf="this.tipoagrupacion.value == 'Comparsas' && this.categoria2.value == 'adulto'">
        <ion-grid>
          <ion-row>
            <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.comparsas_a;"> 
              <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
                <ion-img item-start width="100px" height="80px" [src]="agrupaciones.fotos"></ion-img>
                <ion-card-content >
                  <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                  <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                  <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                  <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </span>

      <span *ngIf="this.tipoagrupacion.value == 'Comparsas' && this.categoria2.value == 'juvenil'">
        <ion-grid>
          <ion-row>
            <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.comparsas_j;">
              <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
                <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
                <ion-card-content >
                  <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                  <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                  <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                  <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </span>

      <span *ngIf="this.tipoagrupacion.value == 'Comparsas' && this.categoria2.value == 'infantil'">
        <ion-grid>
          <ion-row>
            <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngFor="let agrupaciones of service.comparsas_i;">
              <ion-card (click)="irDetalleAgrupacion(agrupaciones.titulo,agrupaciones.nid);">
                <ion-img item-start [src]="agrupaciones.fotos"></ion-img>
                <ion-card-content >
                  <ion-card-title>{{agrupaciones.titulo}}</ion-card-title>
                  <p>{{agrupaciones.modalidad}}{{agrupaciones.categoria | categoria}} ({{agrupaciones.year}})</p>
                  <p color="secundary" text-left>{{agrupaciones.autorletra}}</p>
                  <!--p color="secundary" text-left>{{agrupaciones.musicauthor}}</p-->
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </span>

      <span *ngIf="this.tipoagrupacion.value == 'all'">
        <span *ngFor="let agrupaciones2 of service.agrupaciones">
          <ion-grid>
            <ion-row>
              <span class="carta" *ngFor="let agrupaciones of agrupaciones2.nodes; let i = index; let odd=odd; let even=even;">
                <ion-col size-lg="3" size-md="4" size-sm="6" size-xs="12" *ngIf="(agrupaciones.node.categoria| lowercase)==this.categoria2.value || this.categoria2.value =='all'">
                  <ion-card (click)="irDetalleAgrupacion(agrupaciones.node.titulo,agrupaciones.node.nid);">
                    <ion-img item-start [src]="agrupaciones.node.fotos"></ion-img>
                    <ion-card-content >
                      <ion-card-title>{{agrupaciones.node.titulo}}</ion-card-title>
                      <p>{{agrupaciones.node.modalidad}}{{agrupaciones.node.categoria | categoria}} ({{agrupaciones.node.year}})</p>
                      <p color="secundary" text-left>{{agrupaciones.node.autorletra}}</p>
                      <!--p color="secundary" text-left>{{agrupaciones.node.musicauthor}}</p-->
                    </ion-card-content>
                  </ion-card>
                </ion-col>
              </span>
            </ion-row>
          </ion-grid>
        </span>
      </span>
    
</ion-content>
