<ion-content class="modalMensaje">
  <div class="contenido">
    <div>
      <!--ion-buttons slot="end">
        <ion-button (click)="cerrarModal(0)">
          <ion-icon  name="close-circle" size="large" float-end></ion-icon>
        </ion-button>
      </ion-buttons-->
    </div>
    <div class="mensaje">
      <div class="item">
        <p>{{mensaje1}}</p>
        <p>{{mensaje2}}</p>
      </div>
    </div>
    <ion-buttons slot="end">
      <ion-button (click)="cerrarModal(1)">OK
        <!--ion-icon  name="close-circle" size="large" float-end></ion-icon-->
      </ion-button>
    </ion-buttons>
  </div>
</ion-content>